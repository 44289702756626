import { Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Index from 'pages/Index';
import Login from 'pages/Login';
import UploadECGData from 'pages/UploadECGData';
import ReferPatient from 'pages/ReferPatient';
import { AuthProvider } from 'providers/AuthProvider';
import { ProtectedRoute } from 'components/ProtectedRoute';
import RouteAccess from 'components/RouteAccess';
import NotFound from 'pages/NotFound';
import { PartnerPermission } from 'typescript-partner-client';
import HeartLungAuscultationIndex from 'pages/Auscultation/HeartLungAuscultationIndex';
import HeartLungAuscultationFlow from 'pages/Auscultation/HeartLungAuscultationFlow';
import HeartAuscultation from 'pages/Auscultation/HeartLungAuscultationFlow/HeartAuscultation';
import LungAuscultation from 'pages/Auscultation/HeartLungAuscultationFlow/LungAuscultation';
import AuscultationSummary from 'pages/Auscultation/AuscultationSummary';
import EcgUpload from 'pages/Auscultation/EcgUpload';
import Administration from 'pages/Administration';
import CreateUser from 'pages/Administration/CreateUser';
import Dashboard from 'pages/Index/Dashboard';
import Auscultation from 'pages/Auscultation';
import AuscultationList from 'pages/Auscultation/AuscultationList';
import RecommendationEducation from 'pages/ReferPatient/RecommendationEducation';
import AuscultationEducation from 'pages/Auscultation/AuscultationEducation';
import AdministrationIndex from 'pages/Administration/AdministrationIndex';
import EditPartner from 'pages/Administration/EditPartner';
import EditFacility from 'pages/Administration/EditFacility';
import FacilityInfo from 'pages/Administration/FacilityInfo';
import AddFacility from 'pages/Administration/AddFacility';

export default function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <AuthProvider>
      <SentryRoutes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Index />}>
            <Route index element={<Dashboard />} />
            <Route
              path="ekg"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.PARTNER_DATA_ENTRY}>
                  <UploadECGData />
                </RouteAccess>
              }
            />
            <Route path="/rekommendera" element={<Navigate to="/hanvisning" replace />} />
            <Route
              path="/hanvisning"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.RECOMMEND_PATIENTS}>
                  <ReferPatient />
                </RouteAccess>
              }
            />
            <Route
              path="/hanvisning/utbildning"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.RECOMMEND_PATIENTS}>
                  <RecommendationEducation />
                </RouteAccess>
              }
            />
            <Route
              path="/auskultation"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.AUSCULTATION_FLOW}>
                  <Auscultation />
                </RouteAccess>
              }
            >
              <Route index element={<HeartLungAuscultationIndex />} />
              <Route path="list" element={<AuscultationList />} />
            </Route>
            <Route
              path="/auskultation/utbildning"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.AUSCULTATION_FLOW}>
                  <AuscultationEducation />
                </RouteAccess>
              }
            />
            <Route path="/users" element={<Navigate to="/administration" replace />} />
            <Route
              path="/administration"
              element={
                <RouteAccess requiredAuthority={PartnerPermission.USER_ADMIN}>
                  <Administration />
                </RouteAccess>
              }
            >
              <Route index element={<AdministrationIndex />} />
              <Route path="users/new" element={<CreateUser />} />
              <Route path="user/:userId" element={<CreateUser editMode />} />
              <Route path="partner/edit" element={<EditPartner />} />
              <Route path="facility/:facilityId" element={<FacilityInfo />} />
              <Route path="facility/:facilityId/user/:userId" element={<CreateUser editMode />} />
              <Route path="facility/:facilityId/edit" element={<EditFacility />} />
              <Route path="facility/:facilityId/users/new" element={<CreateUser />} />
              <Route path="facilities/new" element={<AddFacility />} />
            </Route>
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/auskultation/flow" element={<HeartLungAuscultationFlow />}>
            <Route path="heart" element={<HeartAuscultation />} />
            <Route path="lungs" element={<LungAuscultation />} />
            <Route path="ekg" element={<EcgUpload />} />
            <Route path="summary" element={<AuscultationSummary />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </AuthProvider>
  );
}
