import { useTranslation } from 'react-i18next';
import styles from './ErrorMessage.module.scss';

type Props = {
  children?: React.ReactNode;
  error?: any;
  i18nScope?: string;
  highlight?: boolean;
};

export default function ErrorMessage({ children, error, i18nScope = '', highlight = false }: Props) {
  const { t, i18n } = useTranslation();

  if (children) {
    return <span className="error-message">{children}</span>;
  }

  if (!error) {
    return null;
  }

  let i18nKey = i18nScope ? `${i18nScope}.` : '';

  if (error?.body?.code && i18n.exists(`${i18nKey}error.code_${error.body.code}`)) {
    i18nKey = `${i18nKey}error.code_${error.body.code}`;
  } else {
    i18nKey = `${i18nKey}error.generic`;
  }

  return <span className={`error-message ${highlight ? styles.highlight : ''}`}>{t(i18nKey)}</span>;
}
