import useSWR from 'swr';
import { format, parseISO } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { useBackLink } from 'pages/Index';
import { Auscultation, fetcher } from 'api';
import StatusMessage from 'components/StatusMessage';
import SkeletonLoader from 'components/SkeletonLoader';
import List from 'components/List';
import styles from './AuscultationList.module.scss';
import { useAuth } from 'hooks/useAuth';

const byUpdatedDate = (a: Auscultation, b: Auscultation) => {
  const aDate = new Date(a.updatedAt);
  const bDate = new Date(b.updatedAt);

  if (aDate < bDate) {
    return 1;
  }

  if (aDate > bDate) {
    return -1;
  }

  return 0;
};

export default function AuscultationList() {
  const { session } = useAuth();
  const { backLink } = useBackLink();
  const {
    data: auscultations = [],
    error,
    isLoading
  } = useSWR<Auscultation[]>(
    session?.user?.guid ? `/partner/v2/${session.user.guid}/hypertension-physical-examination` : null,
    fetcher
  );

  return (
    <div>
      {backLink}
      <h1>Mina utredningar</h1>
      {isLoading ? (
        <>
          {new Array(5).fill(0).map((_, i) => (
            <SkeletonLoader key={i} height="44px" />
          ))}
        </>
      ) : error ? (
        <StatusMessage status="error">Det gick inte att hämta tidigare utredningar.</StatusMessage>
      ) : auscultations.length ? (
        <List
          items={auscultations.sort(byUpdatedDate)}
          keyExtractor={(i) => i.auscultationId}
          renderItem={(item) => <AuscultationRow auscultation={item} />}
          columnHeaders={
            <>
              <div className="w-25">Namn</div>
              <div className="w-25">Startad</div>
              <div className="w-25">Senast uppdaterad</div>
              <div className="w-25">Status</div>
            </>
          }
        />
      ) : (
        <div>Inga genomförda utredningar</div>
      )}
    </div>
  );
}

const AuscultationRow = ({ auscultation }) => {
  const row = (
    <>
      <div className="w-25">{auscultation.patientName}</div>
      <div className="w-25">
        {format(parseISO(auscultation.createdAt), 'yyyy-MM-dd')} kl. {format(parseISO(auscultation.createdAt), 'HH:mm')}
      </div>
      <div className="w-25">
        {format(parseISO(auscultation.updatedAt), 'yyyy-MM-dd')} kl. {format(parseISO(auscultation.updatedAt), 'HH:mm')}
      </div>
      <div className="w-25">
        <span className={auscultation.completedAt ? styles.completed : undefined}>
          {auscultation.completedAt ? 'Utredning klar' : 'Inkomplett'}
        </span>
      </div>
    </>
  );

  if (!auscultation.completedAt) {
    return (
      <NavLink to="/auskultation" state={{ prefilledPersonalNumber: auscultation.patientPersonalNumber }}>
        {row}
      </NavLink>
    );
  }

  return <div>{row}</div>;
};
