import { CANVAS_SETTINGS } from '../constants';
import { useEffect, useRef } from 'react';

export default function useOscilloscopeVisualization(stream?: MediaStream) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioCtx = useRef<AudioContext>();

  useEffect(() => {
    let requestId;

    if (requestId && !stream) {
      cancelAnimationFrame(requestId);
    }

    if (canvasRef.current && stream) {
      const canvasCtx = canvasRef.current.getContext('2d');

      if (!canvasCtx) {
        throw new Error('Could not create 2D context');
      }

      if (!audioCtx.current) {
        //@ts-ignore
        audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      const source = audioCtx.current.createMediaStreamSource(stream);

      const analyser = audioCtx.current.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      source.connect(analyser);

      const cssWidth = parseInt(getComputedStyle(canvasRef.current).getPropertyValue('width').slice(0, -2));

      const scale = window.devicePixelRatio;
      canvasRef.current.width = Math.floor(cssWidth * scale);

      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      canvasCtx.strokeStyle = CANVAS_SETTINGS.lineColor;
      canvasCtx.lineWidth = 2 * scale;

      const draw = () => {
        analyser.getByteTimeDomainData(dataArray);

        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        canvasCtx.beginPath();

        let sliceWidth = (WIDTH * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          let v = dataArray[i] / 128.0;
          let y = (v * HEIGHT!) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(WIDTH, HEIGHT / 2);
        canvasCtx.stroke();

        requestId = requestAnimationFrame(draw);
      };

      requestId = requestAnimationFrame(draw);
    }

    return () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  });

  return {
    canvasRef
  };
}
