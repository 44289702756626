import { useEffect, useState } from 'react';
import { Listbox as HeadlessListBox } from '@headlessui/react';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { useTranslation } from 'react-i18next';
import styles from './Listbox.module.scss';

type ListboxProps = {
  items: any[];
  onSelectItem: (item: any) => void;
  defaultSelected?: any;
};

export default function Listbox({ items = [], onSelectItem, defaultSelected }: ListboxProps) {
  const [selectedItem, setSelectedItem] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (items.length && !selectedItem && !defaultSelected) {
      setSelectedItem(items[0]);
    }
  }, [items, selectedItem, defaultSelected]);

  useEffect(() => {
    setSelectedItem(defaultSelected);
  }, [defaultSelected]);

  const selectItem = (item: any) => {
    setSelectedItem(item);
    onSelectItem(item);
  };

  return (
    <HeadlessListBox value={selectedItem} onChange={selectItem}>
      <div className={styles.wrapper}>
        <HeadlessListBox.Button className={styles.button}>
          <span className={styles.buttonText}>
            <span>
              {selectedItem?.isNew ? <span className={styles.newItem}>{t('global.new')}</span> : null}
              {selectedItem?.label}
            </span>
          </span>
          <span className={styles.buttonIcon}>
            <Chevron />
          </span>
        </HeadlessListBox.Button>
        <HeadlessListBox.Options className={styles.options}>
          {items.map((item) => (
            <HeadlessListBox.Option
              key={item.id}
              value={item}
              className={({ active, selected }) =>
                `${active ? styles.optionActive : ''} ${selected ? styles.optionSelected : ''} ${styles.option}`
              }
            >
              {item.isNew ? <span className={styles.newItem}>{t('global.new')}</span> : null}
              <span>{item.label}</span>
            </HeadlessListBox.Option>
          ))}
        </HeadlessListBox.Options>
      </div>
    </HeadlessListBox>
  );
}
