import { useEffect, useRef } from 'react';
import useAudioStream from 'hooks/useAudioStream';
import { useAuscultation } from 'hooks/useAuscultation';
import useVolumeVisualization from 'hooks/useVolumeVisualization';
import StatusMessage from 'components/StatusMessage';
import stethoscopeTap from '../../../assets/stethoscope-tap.png';
import styles from './AudioInputVerification.module.scss';

export default function AudioInputVerification() {
  const { audioInputDevice, setNextSetupStepEnabled } = useAuscultation();
  const { endAudioStream, audioStream } = useAudioStream();
  const volumeThreshold = process.env.REACT_APP_ENV === 'production' ? 0.4 : 0.01;
  const { canvasRef, volume } = useVolumeVisualization(audioStream, volumeThreshold);
  const audioCtx = useRef<AudioContext>();
  const mediaSource = useRef<AudioNode>();

  useEffect(() => {
    return () => endAudioStream();
  }, [audioInputDevice]); // eslint-disable-line

  useEffect(() => {
    setNextSetupStepEnabled(volume > volumeThreshold);

    return () => setNextSetupStepEnabled(true);
  }, [volume, setNextSetupStepEnabled, volumeThreshold]);

  useEffect(() => {
    if (!audioCtx.current && audioStream) {
      // @ts-ignore
      audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioCtx.current.createMediaStreamSource(audioStream);
      source.connect(audioCtx.current.destination);

      if (!mediaSource.current) {
        mediaSource.current = source;
      }
    }

    return () => {
      mediaSource.current?.disconnect();
    };
  }, [audioStream]);

  return (
    <>
      <img src={stethoscopeTap} className="border-radius-0" width={480} height={180} alt="Knacka på stetoskopet" />
      <div className={styles.wrapper}>
        <ul className="disc-list">
          <li>
            <strong>Knacka</strong> på stetoskopet för att kontrollera att rätt ljudkälla är vald. Knackningarna
            registreras i upptagningen nedan.
          </li>
          <li>När ljudnivån når över tröskelvärdet kan du gå vidare i inspelningsflödet.</li>
        </ul>
        <h4>Ljudnivå</h4>
        <div className={styles.canvasWrapper}>
          <canvas height={32} width={500} ref={canvasRef}></canvas>
        </div>
        {volume > volumeThreshold ? <StatusMessage status="success">Vald ljudingång är korrekt</StatusMessage> : null}
      </div>
    </>
  );
}
