import { useState, useEffect } from 'react';
import styles from './FadeoutMessage.module.scss';

type FadeoutMessageProps = {
  children: React.ReactNode;
  delaySeconds?: number;
};

export default function FadeoutMessage({ children, delaySeconds = 5 }: FadeoutMessageProps) {
  const [active, setActive] = useState(true);
  const animationDuration = 0.5;
  const style = {
    '--fadeoutDelay': `${delaySeconds}s`,
    '--animationDuration': `${animationDuration}s`
  } as React.CSSProperties;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActive(false);
    }, (delaySeconds + animationDuration) * 1000);

    return () => clearTimeout(timeout);
  }, [delaySeconds]);

  if (!active) {
    return null;
  }

  return (
    <div className={styles.message} style={style}>
      {children}
    </div>
  );
}
