export default function RadioButton({ children, name, id, ...rest }) {
  return (
    <>
      <input type="radio" name={name} id={id} {...rest} />
      <label className="flex-label" htmlFor={id}>
        <div>{children}</div>
      </label>
    </>
  );
}
