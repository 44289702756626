import { useEffect, useState } from 'react';
import StatusMessage from 'components/StatusMessage';
import { NavLink, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Users from '../Users';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import styles from './FacilityInfo.module.scss';
import SkeletonLoader from 'components/SkeletonLoader';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import List from 'components/List';
import { fetcher, generateSetupCode } from 'api';
import FadeoutMessage from 'components/FadeoutMessage';
import { nonFacilityServices } from 'providers/AdministrationProvider';

type Props = {
  facilityId?: string;
};

export default function FacilityInfo({ facilityId }: Props) {
  const params = useParams();
  const { t } = useTranslation();
  const [setupCodes, setSetupCodes] = useState<KioskSetupCode[]>([]);
  const [setupCodeError, setSetupCodeError] = useState(false);
  const {
    data: facility,
    isLoading,
    error
  } = useSWR<Facility>(`/partner/facility/${facilityId || params.facilityId}`, fetcher);

  useEffect(() => {
    if (facility?.setupCodes) {
      setSetupCodes(facility.setupCodes);
    }
  }, [facility]);

  const generateKioskSetupCode = async () => {
    if (!facilityId && !params.facilityId) {
      return;
    }

    setSetupCodeError(false);

    const response = await generateSetupCode(facilityId || params.facilityId!);

    if (!response.ok) {
      setSetupCodeError(true);
      return;
    }

    const code = await response.json();

    setSetupCodes([...setupCodes, code as KioskSetupCode]);
  };

  return error ? (
    <StatusMessage status="error">Det gick inte att hämta mottagningen</StatusMessage>
  ) : (
    <div className="stack-3">
      <div>
        <div className="flex space-between align-center">
          <h2 className="mt-0">{isLoading ? <SkeletonLoader height="1em" width="200px" /> : facility?.name}</h2>
          <div className={styles.editLink}>
            <NavLink to={!facilityId ? `edit` : `facility/${facilityId}/edit`}>
              <PenIcon />
              Redigera
            </NavLink>
          </div>
        </div>
        <div className="stack-1">
          <div>
            <div className="pseudo-label">Status</div>
            <div>
              {isLoading ? (
                <SkeletonLoader height="1em" width="40px" />
              ) : (
                <strong className={styles[facility?.active ? 'active' : 'inactive']}>
                  {facility?.active ? 'Aktiv' : 'Inaktiv'}
                </strong>
              )}
            </div>
          </div>
          <div className="flex gap-1">
            <div className="pseudo-label w-30">
              E-postadress
              <div>
                {isLoading ? <SkeletonLoader height="1em" width="200px" /> : <strong>{facility?.email || '-'}</strong>}
              </div>
            </div>
            <div className="pseudo-label w-30">
              Telefonnummer
              <div>
                {isLoading ? (
                  <SkeletonLoader height="1em" width="200px" />
                ) : (
                  <strong>{facility?.phoneNumber || '-'}</strong>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="pseudo-label">
              Adress
              <div>
                {isLoading ? (
                  <SkeletonLoader height="1em" width="300px" />
                ) : (
                  <strong>
                    {facility?.streetaddress} {facility?.postalCode} {facility?.city}
                  </strong>
                )}
              </div>
            </div>
          </div>
          {facility?.cancellationPolicy ? (
            <div>
              <div className="pseudo-label">
                Avbokningspolicy
                <div className={styles.policy}>{facility.cancellationPolicy}</div>
              </div>
            </div>
          ) : null}
          <div className="pseudo-label">
            Tjänster
            <div>
              {facility?.services
                ?.filter(
                  (service) =>
                    !service.disabledByParent && service.selected && !nonFacilityServices.includes(service.service)
                )
                .map((service) => t(`global.service.${service.service}`))
                .join(', ')}
            </div>
          </div>
        </div>
      </div>
      <KioskSetupCodes
        facility={facility}
        setupCodes={setupCodes}
        onGenerateCode={generateKioskSetupCode}
        error={setupCodeError}
      />
      <Users facilityId={facilityId || params.facilityId} />
    </div>
  );
}

type KioskSetupCodesProps = {
  facility?: Facility;
  setupCodes: KioskSetupCode[];
  onGenerateCode(): void;
  error?: boolean;
};

const KioskSetupCodes = ({ facility, setupCodes, onGenerateCode, error }: KioskSetupCodesProps) => {
  const { t } = useTranslation();

  if (
    !facility?.services
      ?.filter((service) => !service.disabledByParent && service.selected)
      .map((service) => service.service)
      .includes('kiosk')
  ) {
    return null;
  }

  return (
    <>
      <div>
        <h2>Aktiveringskoder</h2>
        <div className="table-header">
          <div>Används för att konfigurera blodtrycksmätningsstationer</div>
          <Button variant="text" onClick={onGenerateCode}>
            Generera ny kod
          </Button>
        </div>
        <List
          items={setupCodes}
          fallback="Inga koder genererade"
          keyExtractor={(i) => i.id}
          renderItem={(item) => (
            <div>
              <div className="w-20">
                <span className={item.status !== 'active' ? 'strike' : ''}>{item.code}</span>
              </div>
              <div>{t(`page.partner_administration.setup_code.${item.status}`)}</div>
            </div>
          )}
          columnHeaders={
            <>
              <div className="w-20">Kod</div>
              <div>Status</div>
            </>
          }
        />
      </div>
      {error ? (
        <FadeoutMessage>
          <StatusMessage status="error">Det gick inte att generera en ny kod</StatusMessage>
        </FadeoutMessage>
      ) : null}
    </>
  );
};
