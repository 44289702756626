import { motion } from 'framer-motion';
import AudioRecorder from 'components/AudioRecorder';
import useAudioStream from 'hooks/useAudioStream';
import { useEffect, useRef } from 'react';
import usePrompt from 'hooks/usePrompt';
import { useAuscultation } from 'hooks/useAuscultation';
import { useTranslation } from 'react-i18next';

type AuscultationListProps = {
  type: 'heart' | 'lungs';
  recordingLocations: string[];
  activeLocation: string;
  setActiveLocation: any;
  goToNext: any;
  goToPrevious: any;
};

export default function AuscultationList({
  type,
  recordingLocations = [],
  activeLocation,
  setActiveLocation,
  goToNext,
  goToPrevious
}: AuscultationListProps) {
  const { endAudioStream, audioStream } = useAudioStream();
  const { allRecordingsOfTypeComplete, flowState } = useAuscultation();
  const audioCtx = useRef<AudioContext>();
  const mediaSource = useRef<AudioNode>();
  const audioDestination = useRef<AudioDestinationNode>();

  const { t } = useTranslation();
  usePrompt(
    t('page.auscultation.prompt.unfinished_recordings'),
    !allRecordingsOfTypeComplete(type) && flowState === 'inProgress'
  );

  useEffect(() => {
    if (!audioCtx.current && audioStream) {
      // @ts-ignore
      audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioCtx.current.createMediaStreamSource(audioStream);
      source.connect(audioCtx.current.destination);

      if (!mediaSource.current) {
        mediaSource.current = source;
        audioDestination.current = audioCtx.current.destination;
      }
    }

    return () => {
      mediaSource.current?.disconnect();
    };
  }, [audioStream]);

  useEffect(() => {
    return () => endAudioStream();
  }, []); // eslint-disable-line

  return (
    <motion.div
      key={`recordingList${type}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="stack"
    >
      {recordingLocations.map((location, i, arr) => (
        <AudioRecorder
          key={location}
          location={location}
          recordingType={type}
          isActive={activeLocation === location}
          onNext={i !== arr.length - 1 ? goToNext : undefined}
          onPrevious={i > 0 ? goToPrevious : undefined}
          onClick={() => setActiveLocation(location)}
          order={i + 1}
          audioStream={audioStream}
          mediaSource={mediaSource.current}
          audioDestination={audioDestination.current}
        />
      ))}
    </motion.div>
  );
}
