import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuscultation } from './useAuscultation';

export default function useAudioRecorder() {
  const mediaRecorder = useRef<MediaRecorder | undefined>();
  const mediaStream = useRef<MediaStream | undefined>();
  const mediaChunks = useRef<Blob[]>([]);
  const requestAnimationFrameId = useRef<number>(0);

  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [status, setStatus] = useState<'idle' | 'acquiring' | 'ready' | 'recording' | 'stopping' | 'stopped'>('idle');

  const [devicePermission, setDevicePermission] = useState<'denied' | 'granted' | undefined>();
  const [audioDeviceAvailable, setAudioDeviceAvailable] = useState<boolean>(true);
  const [deviceError, setDeviceError] = useState<unknown>();
  const { audioInputDevice, timeSlice, setIsRecording } = useAuscultation();

  useEffect(() => {
    if (deviceError instanceof DOMException) {
      if (deviceError.name === 'NotAllowedError') {
        setDevicePermission('denied');
      } else if (deviceError.name === 'NotFoundError') {
        setAudioDeviceAvailable(false);
      }
    }
  }, [deviceError]);

  useEffect(() => {
    if (status === 'recording') {
      stopRecording();
      clearMediaBlob();
    }
  }, [audioInputDevice]); // eslint-disable-line

  async function getMediaStream() {
    setStatus('acquiring');

    if (!window.navigator.mediaDevices) {
      throw new Error('Media Devices missing');
    }

    let stream = await window.navigator.mediaDevices.getUserMedia({
      audio: { deviceId: audioInputDevice, autoGainControl: true, echoCancellation: true, noiseSuppression: true }
    });

    setDevicePermission('granted');
    setStatus('ready');

    return stream;
  }

  function clearMediaStream() {
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = undefined;
    }
  }

  function clearMediaBlob() {
    setAudioBlob(null);
  }

  async function startRecording() {
    if (!mediaStream.current) {
      try {
        mediaStream.current = await getMediaStream();
      } catch (error: unknown) {
        // Todo: handle more errors
        setDeviceError(error);
        return;
      }
    }

    clearMediaBlob();

    mediaChunks.current = [];

    mediaRecorder.current = new MediaRecorder(mediaStream.current!);
    mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorder.current.addEventListener('stop', handleStop);
    mediaRecorder.current.start(timeSlice);

    setStatus('recording');
    setIsRecording(true);
  }

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current = undefined;
      clearMediaStream();
      window.cancelAnimationFrame(requestAnimationFrameId.current);

      setStatus('stopping');
    }
  }, []);

  function handleDataAvailable(e) {
    if (e.data.size) {
      mediaChunks.current.push(e.data);
    }
  }

  function handleStop() {
    if (mediaChunks.current.length) {
      const blob = new Blob(mediaChunks.current, { type: 'audio/mp3' });

      setAudioBlob(blob);
    }

    setStatus('stopped');
    setIsRecording(false);
  }

  return {
    stopRecording,
    startRecording,
    clearMediaBlob,
    status,
    audioBlob,
    audioStream: mediaStream.current,
    timeSlice,
    devicePermission,
    audioDeviceAvailable
  };
}
