import useSWR from 'swr';
import List from 'components/List';
import SkeletonLoader from 'components/SkeletonLoader';
import StatusMessage from 'components/StatusMessage';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { fetcher } from 'api';

export default function Facilities() {
  const { session } = useAuth();
  const {
    data: facilityList,
    isLoading,
    error
  } = useSWR<Facility[]>(session?.user?.partnerGuid ? `/partner/${session.user.partnerGuid}/facility` : null, fetcher);
  const NUM_SKELETON_ROWS = 3;

  return (
    <div>
      <div className="space-between align-center">
        <h2>
          Mottagningar {facilityList?.length ? <span className="heading-count">({facilityList.length})</span> : null}
        </h2>
        <div>
          <NavLink to="facilities/new">+ Lägg till mottagning</NavLink>
        </div>
      </div>
      {isLoading ? (
        <>
          {new Array(NUM_SKELETON_ROWS).fill(0).map((_, i) => (
            <SkeletonLoader key={i} height="44px" />
          ))}
        </>
      ) : error ? (
        <StatusMessage status="error">Det gick inte att hämta mottagningslistan.</StatusMessage>
      ) : facilityList?.length ? (
        <List
          items={facilityList}
          keyExtractor={(facility) => facility.guid!}
          renderItem={(item) => <FacilityRow facility={item} />}
          columnHeaders={
            <>
              <div className="column name">Namn</div>
              <div className="column email">E-postadress</div>
              <div>Personal</div>
            </>
          }
        />
      ) : (
        <div>Inga mottagningar</div>
      )}
    </div>
  );
}

type FacilityRowProps = {
  facility: Facility;
};

const FacilityRow = ({ facility }: FacilityRowProps) => {
  return (
    <NavLink to={`facility/${facility.guid}`}>
      <div className="column name">{facility.name}</div>
      <div className="column email">{facility.email || '-'}</div>
      <div>{facility.userCount} användare</div>
    </NavLink>
  );
};
