import React from 'react';
import styles from './AudioPlayback.module.scss';

type AudioPlaybackProps = {
  duration: number;
  isPlaying: boolean;
  children: React.ReactNode;
  playable: boolean;
  onPlay(): void;
  onStop(): void;
};

export default function AudioPlayback({ duration, isPlaying, children, playable, onPlay, onStop }: AudioPlaybackProps) {
  const progressStyle = {
    '--progressBarDuration': `${duration}s`
  } as React.CSSProperties;

  return (
    <div className={styles.playbackWrapper}>
      <div className={styles.controls}>
        {!isPlaying ? (
          <button className={styles.playButton} onClick={onPlay} disabled={!playable}>
            Play
          </button>
        ) : (
          <button className={styles.stopButton} onClick={onStop}>
            Pause
          </button>
        )}
      </div>
      <div className={styles.canvasWrapper}>
        <div className={`${styles.progress} ${isPlaying ? styles.playing : ''}`} style={progressStyle}></div>
        {children}
      </div>
    </div>
  );
}
