import { CANVAS_SETTINGS } from '../constants';
import { useEffect, useRef, useState } from 'react';

export default function useWaveFormVisualization(stream?: MediaStream, duration = 5) {
  const [running, setRunning] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioCtx = useRef<AudioContext>();
  const requestAnimationFrameId = useRef<number>();

  useEffect(() => {
    if (!running) {
      requestAnimationFrameId.current && cancelAnimationFrame(requestAnimationFrameId.current);
    }
  }, [running]);

  useEffect(() => {
    let requestId;

    if (requestId && !stream) {
      console.log('cancelling');
      cancelAnimationFrame(requestId);
    }

    if (canvasRef.current && stream) {
      const canvasCtx = canvasRef.current.getContext('2d');

      if (!canvasCtx) {
        throw new Error('Could not create 2D context');
      }

      if (!audioCtx.current) {
        //@ts-ignore
        audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      const source = audioCtx.current.createMediaStreamSource(stream);

      const analyser = audioCtx.current.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Float32Array(bufferLength);

      source.connect(analyser);

      let previousTimeStamp = performance.now();
      const cssWidth = parseInt(getComputedStyle(canvasRef.current).getPropertyValue('width').slice(0, -2));
      const cssHeight = parseInt(getComputedStyle(canvasRef.current).getPropertyValue('height').slice(0, -2));

      const scale = window.devicePixelRatio;
      canvasRef.current.width = Math.floor(cssWidth * scale);
      canvasRef.current.height = Math.floor(cssHeight * scale);

      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      let xPos = 0;
      let yPos = HEIGHT! / 2;
      canvasCtx.lineWidth = 2 * scale;
      canvasCtx.strokeStyle = CANVAS_SETTINGS.lineColor;
      let xValues: number[] = [];
      let yValues: number[] = [];

      const normalizeData = (arr: number[]) => {
        const multiplier = Math.pow(Math.max(...arr), -1);
        return arr.map((n) => n * multiplier);
      };

      const pixelsPerSecond = WIDTH / duration;

      const draw = (timestamp: number) => {
        const elapsed = timestamp - previousTimeStamp;
        const speedFactor = pixelsPerSecond * (elapsed / 1000); // Elapsed is in milliseconds so divide by 1000
        previousTimeStamp = timestamp;

        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        analyser.getFloatTimeDomainData(dataArray);

        const normalizedYValues = normalizeData(yValues);

        canvasCtx.beginPath();
        if (xValues.length > 1) {
          canvasCtx.moveTo(0, HEIGHT / 2);
          for (let i = 0; i < xValues.length - 1; i++) {
            canvasCtx.lineTo(xValues[i + 1], (normalizedYValues[i + 1] * HEIGHT) / 2 + HEIGHT / 2);
          }
        }
        canvasCtx.stroke();

        xPos += speedFactor;
        yPos = dataArray[0];

        xValues.push(xPos);
        yValues.push(yPos);

        if (running) {
          requestId = requestAnimationFrame(draw);
        }
      };

      requestId = requestAnimationFrame(draw);
    }

    return () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  });

  const clearCanvas = () => {
    if (canvasRef.current) {
      const canvasCtx = canvasRef.current.getContext('2d');

      if (!canvasCtx) {
        throw new Error('Could not create 2D context');
      }

      canvasCtx.fillStyle = CANVAS_SETTINGS.backgroundColor;
      canvasCtx.fillRect(0, 0, canvasRef.current?.width!, canvasRef.current?.height!);
    }
  };

  const startVisualization = () => {
    setRunning(true);
  };

  const stopVisualization = () => {
    setRunning(false);
  };

  return {
    canvasRef,
    clearCanvas,
    stopVisualization,
    startVisualization
  };
}
