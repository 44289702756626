import { useEffect, useState } from 'react';
import { useAuscultation } from './useAuscultation';

export default function useRequestMediaDeviceAccess() {
  const { audioInputDevice, setMediaError, setMediaAccessGranted } = useAuscultation();
  const [mediaAccessGranted, setAccessGranted] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    if (!window.navigator.mediaDevices) {
      setError(new Error('Media Devices missing'));
    } else {
      window.navigator.mediaDevices
        .getUserMedia({
          audio: { deviceId: audioInputDevice }
        })
        .then((stream) => {
          setMediaAccessGranted(true);
          setAccessGranted(true);
          stream.getTracks().forEach((track) => track.stop());
          setMediaError(undefined);
          setError(undefined);
        })
        .catch((error) => {
          setMediaAccessGranted(false);
          setAccessGranted(false);
          setError(error);
          setMediaError(error);
          console.log(error);
        });
    }
  }, [audioInputDevice, setMediaError, setMediaAccessGranted]);

  return {
    mediaAccessGranted,
    error
  };
}
