import StatusMessage from 'components/StatusMessage';
import useRequestMediaDeviceAccess from 'hooks/useRequestMediaDeviceAccess';
import micAccessDialog from '../../../assets/mic-access-dialog.png';

export default function RequestMicrophoneAccess() {
  const { mediaAccessGranted, error } = useRequestMediaDeviceAccess();

  const getContent = () => {
    if (mediaAccessGranted === false) {
      return (
        <StatusMessage status="error">
          Åtkomst till mikrofon saknas. Ge åtkomst till mikrofonen i din webbläsares inställningar för att gå vidare.
        </StatusMessage>
      );
    }

    if (mediaAccessGranted) {
      return <StatusMessage status="success">Klart! Du kan nu gå vidare till nästa steg.</StatusMessage>;
    }

    if (error) {
      return <StatusMessage status="error">Något gick snett.</StatusMessage>;
    }

    return null;
  };

  return (
    <>
      <img src={micAccessDialog} width={480} height={180} alt="Tillgångsdialog för mikrofon" />
      <div className="padded-content mt-1">
        <ol className="mt-1">
          <li>Du ska ha fått en förfrågan om att tillåta webbläsaren att spela in ljud via mikrofonen.</li>
          <li>
            <strong>OBS!</strong> Du behöver godkänna förfrågan att kunna spela in ljud.
          </li>
        </ol>
        <div className="mt-1">{getContent()}</div>
      </div>
    </>
  );
}
