import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './RouteAccess.module.scss';

export default function RouteAccess({ children, requiredAuthority }) {
  const { authorities } = useAuth();
  const { t } = useTranslation();

  if (!authorities.includes(requiredAuthority)) {
    return (
      <main className={styles.wrapper}>
        <section>
          <h1>{t('global.page_access_denied')}</h1>
          <div>
            <Link to="/">{t('page.not_found.back_to_start')}</Link>
          </div>
        </section>
      </main>
    );
  }
  return children;
}
