export default function AddFacility() {
  return (
    <div>
      <h2>Lägg till mottagning</h2>
      <div className="mt-2">
        För att lägga till en mottagning till er organisation, vänligen kontakta{' '}
        <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a>.
      </div>
    </div>
  );
}
