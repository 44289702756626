import { Dialog } from '@headlessui/react';
import Button from 'components/Button';
import styles from './Modal.module.scss';

type ModalProps = {
  isOpen: boolean;
  onClose(e?: any): void;
  onAction?(e?: any): void;
  actionText?: string;
  title?: string;
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  headerAction?: React.ReactNode;
  noPadding?: boolean;
  cancelText?: string;
  isActionCompleting?: boolean;
  hint?: React.ReactNode;
};

export default function Modal({
  isOpen,
  onClose,
  onAction,
  actionText,
  title,
  children,
  size = 'medium',
  headerAction,
  cancelText,
  isActionCompleting,
  noPadding: removePadding = false,
  hint
}: ModalProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.wrapper}>
      <div className={styles.backdrop} aria-hidden="true" />
      <div className={styles.panelWrapper}>
        <Dialog.Panel className={`${styles.panel} ${styles[size]}`}>
          <header className={`${title ? styles.headerBorder : ''}`}>
            <button onClick={onClose} className={styles.closeButton}></button>
            {title ? <Dialog.Title>{title}</Dialog.Title> : null}
            {headerAction ? <div className={styles.headerAction}>{headerAction}</div> : null}
          </header>
          <div className={`${styles.childWrapper} ${removePadding ? styles.noPadding : ''}`}>{children}</div>
          {onAction && actionText ? (
            <div className={styles.action}>
              {cancelText ? (
                <Button variant="tertiary" onClick={onClose}>
                  {cancelText}
                </Button>
              ) : null}
              <Button onClick={onAction} isLoading={isActionCompleting}>
                {actionText}
              </Button>
            </div>
          ) : null}
          {hint && <div className={styles.hint}>{hint}</div>}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
