import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FileUpload.module.scss';

type FileUploadProps = {
  name: string;
  label?: string;
  isUploading: boolean;
  uploadSuccess: boolean;
  filename?: string;
  accept?: string;
  onChange(e: any): Promise<void> | void;
};

export default function FileUpload({
  name,
  label,
  isUploading,
  uploadSuccess,
  filename,
  accept = '.pdf',
  onChange
}: FileUploadProps) {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      {label ? <div className={styles.fakeLabel}>EKG-fil</div> : null}
      <div className={styles.fileSelect}>
        <div
          className={`fake-input disabled ${isUploading ? 'icon loading' : uploadSuccess ? 'icon success' : ''}`}
          onClick={() => fileInput.current?.click()}
        >
          <span className={styles.filenameWrapper}>{filename || t('page.ecg_upload.no_file_selected')}</span>
        </div>
        <input
          type="file"
          id={`${name}-file`}
          name={name}
          required={!filename}
          accept={accept}
          onChange={onChange}
          ref={fileInput}
        />
        <label htmlFor={`${name}-file`}>
          {filename ? t('page.ecg_upload.choose_different_file') : t('page.ecg_upload.choose_file')}
        </label>
      </div>
    </>
  );
}
