import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import useSteps from 'hooks/useSteps';
import Button from 'components/Button';
import styles from './SetupSteps.module.scss';

type Props = {
  steps: any[];
  showStepCount?: boolean;
  showSkip?: boolean;
  displayInModal?: boolean;
  finishDisabled?: boolean;
  nextStepEnabled?: boolean;
  onFinish: () => void;
  onStepChanged?: (step: number) => void;
};

export default function SetupSteps({
  steps,
  showStepCount = true,
  showSkip = true,
  displayInModal = false,
  onFinish,
  finishDisabled = false,
  nextStepEnabled = true,
  onStepChanged
}: Props) {
  const { currentStep, totalSteps, direction, nextStep, previousStep } = useSteps(steps.length, onFinish);

  const variants = {
    enter: (direction: string) => {
      return {
        x: direction === 'right' ? 400 : -400,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction: string) => {
      return {
        x: direction === 'left' ? 400 : -400,
        opacity: 0
      };
    }
  };

  const previous = () => {
    previousStep();
    onStepChanged && onStepChanged(currentStep - 1);
  };

  const next = () => {
    nextStep();
    onStepChanged && onStepChanged(currentStep + 1);
  };

  const duration = 0.2;

  return (
    <div className={`${styles.wrapper} ${displayInModal ? styles.displayInModal : ''}`}>
      <LayoutGroup>
        <div>
          {showStepCount && steps.length > 1 ? (
            <small>
              Steg {currentStep} av {totalSteps}
            </small>
          ) : null}
          <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
            <motion.div
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'easeInOut', duration },
                opacity: { duration }
              }}
              className={styles.stepWrapper}
              key={currentStep}
            >
              {steps[currentStep - 1]}
            </motion.div>
          </AnimatePresence>
        </div>
        <motion.div layout transition={{ delay: 0, duration }} className={styles.actions}>
          {showSkip && steps.length > 1 ? (
            <Button variant="secondary" onClick={onFinish} fullWidth={displayInModal}>
              Hoppa över
            </Button>
          ) : currentStep > 1 ? (
            <Button variant="tertiary" onClick={previous} fullWidth={displayInModal}>
              Föregående
            </Button>
          ) : null}
          <Button
            onClick={next}
            fullWidth={displayInModal}
            disabled={!nextStepEnabled || (currentStep === totalSteps && finishDisabled)}
          >
            {currentStep < totalSteps ? 'Nästa steg' : 'Klar'}
          </Button>
        </motion.div>
      </LayoutGroup>
    </div>
  );
}
