import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import packageJson from '../../package.json';
import {
  loginWithCredentials,
  refreshAuthToken,
  setAuthToken,
  beginLoginFlow as apiBeginLoginFlow,
  refreshQrCode
} from 'api';
import { clearStorage, loadSessionState, saveSessionState } from 'services/sessionStorage';
import { isSessionNearingExpiry, isValidSession, throttle } from 'utils';
import Mixpanel from 'services/tracking';

type LoginState = 'idle' | 'polling' | 'user_signing' | 'error';

type ContextValue = {
  session?: LoginResponsePartner;
  authorities: string[];
  error?: unknown;
  authenticating: boolean;
  sessionTimedOut: boolean;
  activeQrCode?: Base64SvgImage;
  onLogin?(identifier: string, password?: string, devMode?: boolean): Promise<void>;
  onLogout?(): void;
  updateUserProperty(property: string, newValue: any): void;
  beginLoginFlow(): Promise<void>;
  updateQrCode(sessionId: string): Promise<void>;
  cancelLogin: () => void;
  loginState: LoginState;
};

const storedSession = loadSessionState('auth', true);

if (isValidSession(storedSession)) {
  setAuthToken(storedSession.jwt);
}

export const AuthContext = React.createContext<ContextValue | undefined>(undefined);

const POLL_RATE_IN_MS = 1000;

const getTimeUntilNextRequest = (requestTime: number) => {
  return POLL_RATE_IN_MS - requestTime > 0 ? POLL_RATE_IN_MS - requestTime : 0;
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [session, setSession] = useState<LoginResponsePartner | undefined>(storedSession);
  const [error, _setError] = useState<unknown>();
  const [loading, setLoading] = useState(false);
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const [authorities, setAuthorities] = useState<string[]>(
    storedSession
      ? storedSession.user?.services?.filter((s) => s.selected && !s.disabledByParent).map((s) => s.service)
      : []
  );
  const sessionMonitor = useRef<NodeJS.Timeout>();
  const [activeQrCode, setActiveQrCode] = useState<Base64SvgImage>();
  const [loginState, _setLoginState] = useState<LoginState>('idle');
  const loginStateRef = useRef<LoginState>('idle');
  const timeoutRef = useRef<NodeJS.Timeout>();
  const qrAbortController = useRef<AbortController>();

  const refreshTokenIfNeeded = async () => {
    if (session?.jwt && isSessionNearingExpiry(session) && isValidSession(session)) {
      renewSession();
    }
  };

  const renewSession = async () => {
    if (session) {
      const tokenRefreshResponse = await refreshAuthToken(session.jwt);
      setSession(tokenRefreshResponse);
      setAuthToken(tokenRefreshResponse.jwt);
      setSessionTimedOut(false);
      saveSessionState(tokenRefreshResponse, 'auth');
    }
  };

  useEffect(() => {
    renewSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trackActivity = throttle(refreshTokenIfNeeded, 5000);
    const events = ['mousemove', 'touchstart'];
    events.forEach((event) => window.addEventListener(event, trackActivity));

    return () => events.forEach((event) => window.removeEventListener(event, trackActivity));
  }, [session]); // eslint-disable-line

  useEffect(() => {
    if (sessionMonitor.current) {
      clearInterval(sessionMonitor.current);
    }

    if (session) {
      sessionMonitor.current = setInterval(() => {
        const sessionTimedOut = !isValidSession(session);
        setSessionTimedOut(sessionTimedOut);
        if (sessionTimedOut && sessionMonitor.current) {
          clearInterval(sessionMonitor.current);
        }
      }, 30_000);
    }

    return () => {
      if (sessionMonitor.current) {
        clearInterval(sessionMonitor.current);
      }
    };
  }, [session]);

  const handleLoginSuccess = useCallback(
    (loginResponse: LoginResponsePartner) => {
      const authorities =
        loginResponse.user?.services?.filter((s) => s.selected && !s.disabledByParent).map((s) => s.service) || [];

      setSession(loginResponse);
      setAuthorities(authorities);
      setLoading(false);
      clearError();
      setSessionTimedOut(false);
      setAuthToken(loginResponse.jwt);
      saveSessionState(loginResponse, 'auth');

      if (loginResponse.user?.guid) {
        Mixpanel.setProperty('$name', `${loginResponse.user.givenName} ${loginResponse.user.familyName}`);
        Mixpanel.setProperty('partnerName', loginResponse.user.partnerName);
        Mixpanel.setProperty('clientVersion', packageJson.version);
        Mixpanel.identify(loginResponse.user.guid);
      }

      //@ts-expect-error
      const { from } = location.state || { from: { pathname: '/' } };

      navigate(from);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state, navigate]
  );

  const handleDevLogin = async (identifier: string, password?: string, devMode = false): Promise<void> => {
    setLoading(true);
    try {
      const loginResponse = await loginWithCredentials(identifier, password);

      handleLoginSuccess(loginResponse);
    } catch (err) {
      setError(err);
      const error = err as any;
      Mixpanel.track('Error|Login', { errorCode: error?.body?.code || '-1' });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setSession(undefined);
    setSessionTimedOut(false);
    clearStorage();
    clearError();
    if (sessionMonitor.current) {
      clearInterval(sessionMonitor.current);
    }
  };

  const updateUserProperty = (property: string, newValue: any) => {
    if (!session?.user) {
      return;
    }

    const existingProperties = session.user.properties ?? {};

    const updatedSession = {
      ...session,
      user: { ...session?.user, properties: { ...existingProperties, [property]: newValue } }
    };

    setSession(updatedSession);
    saveSessionState(updatedSession, 'auth');
  };

  const beginLoginFlow = async () => {
    setLoginState('polling');

    try {
      const start = new Date();
      const response = await apiBeginLoginFlow();
      const finish = new Date();
      const requestDuration = finish.valueOf() - start.valueOf();
      setActiveQrCode(window.atob(response.QRCode));
      timeoutRef.current = setTimeout(() => {
        updateQrCode(response.sessionId);
      }, getTimeUntilNextRequest(requestDuration));
    } catch (err) {
      setError(err);
    }
  };

  const updateQrCode = async (sessionId: string) => {
    try {
      qrAbortController.current = new AbortController();
      const start = new Date();
      const response = await refreshQrCode(sessionId, qrAbortController.current.signal);
      const finish = new Date();
      const requestDuration = finish.valueOf() - start.valueOf();
      const status = response.sessionResponse?.grandidObject?.message?.status;
      const hintCode = response.sessionResponse?.grandidObject?.message?.hintCode;

      if (hintCode === 'userSign') {
        setLoginState('user_signing');
      }

      if (status === 'failed') {
        if (hintCode === 'userCancel') {
          setLoginState('idle');
        } else {
          setError('Login failed');
        }
        Mixpanel.track('Error|Login', { errorCode: hintCode === 'userCancel' ? '98' : '99' });
      }

      if (status === 'succeeded' && response.loginResponse) {
        setActiveQrCode(undefined);
        handleLoginSuccess(response.loginResponse);
        return;
      }

      setActiveQrCode(window.atob(response.sessionResponse.grandidObject.QRCode));

      if (['polling', 'user_signing'].includes(loginStateRef.current)) {
        timeoutRef.current = setTimeout(() => {
          updateQrCode(sessionId);
        }, getTimeUntilNextRequest(requestDuration));
      }
    } catch (err) {
      setError(err);
      const error = err as any;
      Mixpanel.track('Error|Login', { errorCode: error?.body?.code || '-1' });
    }
  };

  const cancelLogin = () => {
    setLoginState('idle');
    setActiveQrCode(undefined);
    clearError();
    // @ts-ignore
    clearTimeout(timeoutRef.current);
    qrAbortController.current?.abort();
  };

  const setError = (err: unknown) => {
    _setError(err);
    setLoginState('error');
    // @ts-ignore
    clearTimeout(timeoutRef.current);
  };

  const clearError = () => {
    _setError(undefined);
    setLoginState('idle');
  };

  const setLoginState = (newState: LoginState) => {
    _setLoginState(newState);
    loginStateRef.current = newState;
  };

  const value = {
    session,
    authorities,
    error,
    authenticating: loading,
    sessionTimedOut,
    loginState,
    activeQrCode,
    onLogin: handleDevLogin,
    onLogout: handleLogout,
    updateUserProperty,
    beginLoginFlow,
    updateQrCode,
    cancelLogin
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
