import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { lookupUser, submitScreening } from 'api';
import Button from 'components/Button';
import styles from './UploadECGData.module.scss';
import FadeoutMessage from 'components/FadeoutMessage';
import StatusMessage from 'components/StatusMessage';
import RadioButton from 'components/RadioButton';
import CheckBox from 'components/Checkbox';
import PersonalIdentityNumberVerification from 'components/PersonalIdentityNumberVerification';
import { useBackLink } from 'pages/Index';

export default function UploadECGData() {
  const fileInput = useRef<HTMLInputElement>(null);
  const form = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const [patientNameError, setPatientNameError] = useState<unknown>();
  const [filename, setFilename] = useState<string | undefined>();
  const [normalHeartLungAuscultation, setNormalHeartLungAuscultation] = useState<boolean | string>('indeterminate');
  const [submittingForm, setSubmittingForm] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<ActionStatus | undefined>();
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const resetRef = useRef<Function>(null);
  const { backLink } = useBackLink();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (patientNameError) {
      return;
    }

    const formData = new FormData(e.target);

    try {
      setUploadStatus(undefined);
      setSubmittingForm(true);
      await submitScreening(formData);
      setUploadStatus('success');
      resetForm();
    } catch (err: unknown) {
      setUploadStatus('error');
    } finally {
      setSubmittingForm(false);
    }
  };

  const resetForm = () => {
    form.current?.reset();
    if (resetRef.current) {
      resetRef.current();
    }
    setFilename('');
    setNormalHeartLungAuscultation('indeterminate');
    setSubmissionAttempted(false);
  };

  const fileMissingError = !filename && submissionAttempted;

  return (
    <div className="narrow-page">
      {backLink}
      <h1>{t('page.ecg_upload.header')}</h1>
      <p>{t('page.ecg_upload.description')}</p>
      <form onSubmit={handleSubmit} ref={form}>
        <PersonalIdentityNumberVerification
          onReset={resetRef}
          onError={(error: unknown) => setPatientNameError(error)}
          apiMethod={lookupUser}
        />

        <label>{t('page.ecg_upload.ecg_results')}</label>
        {fileMissingError ? <span className="error-message">{t('page.ecg_upload.file_missing')}</span> : null}
        <div className={styles.fileSelect}>
          <div
            className={`fake-input disabled ${fileMissingError ? 'error' : ''}`}
            onClick={() => fileInput.current?.click()}
          >
            {filename || t('page.ecg_upload.no_file_selected')}
          </div>
          <input
            type="file"
            id="ecg-results"
            data-testid="ecg-results"
            name="electrocardiogram"
            required
            accept=".pdf"
            onChange={(e) => setFilename(e.target?.files && e.target.files.length ? e.target.files[0].name : '')}
            ref={fileInput}
          />
          <label htmlFor="ecg-results">
            {filename ? t('page.ecg_upload.choose_different_file') : t('page.ecg_upload.choose_file')}
          </label>
        </div>

        <fieldset>
          <legend>{t('page.ecg_upload.heart_lung_auscultation')}</legend>
          <RadioButton
            name="normalHeartLungAuscultation"
            id="nhla-true"
            value="true"
            checked={normalHeartLungAuscultation === true}
            required
            onChange={() => setNormalHeartLungAuscultation(true)}
          >
            {t('page.ecg_upload.normal_auscultation')}
          </RadioButton>

          <RadioButton
            name="normalHeartLungAuscultation"
            id="nhla-false"
            value="false"
            checked={normalHeartLungAuscultation === false}
            required
            onChange={() => setNormalHeartLungAuscultation(false)}
          >
            {t('page.ecg_upload.irregular_auscultation')}
          </RadioButton>

          {normalHeartLungAuscultation === false ? (
            <div className={styles.description}>
              <textarea
                id="anomaly-description"
                required
                name="heartLungAuscultationFindings"
                placeholder={t('page.ecg_upload.describe_irregularity')}
              />
            </div>
          ) : null}
        </fieldset>

        <div>
          <label htmlFor="other-information">{t('page.ecg_upload.other_information')}</label>
          <textarea id="other-information" name="otherInformation" />
        </div>

        <div>
          <CheckBox id="verify" required>
            {t('page.ecg_upload.confirm_label')}
          </CheckBox>
        </div>

        <Button
          type="submit"
          size="large"
          isLoading={submittingForm}
          fullWidth={true}
          onClick={() => setSubmissionAttempted(true)}
        >
          {t('page.ecg_upload.submit_button')}
        </Button>
      </form>
      {uploadStatus ? (
        <FadeoutMessage>
          <StatusMessage status={uploadStatus}>
            <span>{t(`page.ecg_upload.submission_${uploadStatus}`)}</span>
          </StatusMessage>
        </FadeoutMessage>
      ) : null}
    </div>
  );
}
