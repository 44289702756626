import React, { useEffect, useState } from 'react';
import localforage from 'localforage';
import { storeAuscultationRecording, updateAuscultationObservation } from 'api';
import Mixpanel from 'services/tracking';

export type Patient = {
  personalNumber: string;
  id?: string;
  givenName?: string;
  familyName?: string;
  nextGeneration?: boolean;
};

export type AuscultationSession = {
  id?: string;
  recordings?: any[];
  comment?: string;
  fileId?: string;
};

type AuscultationRequirements = {
  auscultationRequirementIds: Record<string, number | undefined>;
  commentRequirementId: number | undefined;
};

type FlowState = 'notStarted' | 'inProgress';

type ContextValue = {
  audioInputDevice?: string;
  setAudioInputDevice(deviceId: string): void;
  recordings: any;
  storeRecording(name: string, audioBlob: Blob, metadata: Record<string, any>): void;
  getRecording(name: string): StoredRecording;
  deleteRecording(name: string): void;
  hasRecording(name: string): boolean;
  hasRecordingOfType(type: 'heart' | 'lungs'): boolean;
  allRecordingsOfTypeComplete(type: 'heart' | 'lungs'): boolean;
  getUnsavedRecordings(): any;
  markAllRecordingsSaved(): void;
  timeSlice: number;
  setTimeSlice(timeSlice: number): void;
  clearLocalData(): Promise<any>;
  clearRecordingData(): Promise<any>;
  patient?: Patient;
  setPatient(patient: Patient, storeLocally?: boolean): void;
  auscultationId?: string;
  setAuscultationId(id: string): Promise<string>;
  ecgFilename?: string;
  setEcgFilename(filename: string): void;
  mediaError: unknown;
  setMediaError(error: unknown): void;
  demoMode: boolean;
  setDemoMode(mode: boolean): void;
  isRecording: boolean;
  setIsRecording(state: boolean): void;
  mediaAccessGranted: boolean;
  setMediaAccessGranted(accessGranted: boolean): void;
  isFirstTimeSetup: boolean;
  flowState: FlowState;
  setFlowState(newState: FlowState): void;
  loadAuscultationSession(auscultationSession: AuscultationSession): Promise<boolean>;
  storedComment?: string;
  nextSetupStepEnabled: boolean;
  setNextSetupStepEnabled(newState: boolean): void;
  isNextGen: boolean;
  setRequirementIds(requirementIds: AuscultationRequirements): void;
  requirementIds: AuscultationRequirements;
  saveUnsavedNextGenRecordings(): Promise<Response[]>;
};

type StoredRecording = {
  blob: Blob;
  metadata: Record<string, any>;
};

export const AuscultationContext = React.createContext<ContextValue>({
  audioInputDevice: undefined,
  setAudioInputDevice: () => {},
  recordings: {},
  storeRecording: () => {},
  getRecording: () => {
    return { blob: new Blob(), metadata: {} };
  },
  deleteRecording: () => {},
  hasRecording: () => false,
  hasRecordingOfType: () => false,
  allRecordingsOfTypeComplete: () => false,
  getUnsavedRecordings: () => {},
  markAllRecordingsSaved: () => {},
  timeSlice: 5000,
  setTimeSlice: () => {},
  clearLocalData: () => new Promise((r) => r(true)),
  clearRecordingData: () => new Promise((r) => r(true)),
  patient: undefined,
  setPatient: () => {},
  auscultationId: undefined,
  setAuscultationId: () => {
    return new Promise((resolve) => resolve(''));
  },
  ecgFilename: undefined,
  setEcgFilename: () => {},
  mediaError: undefined,
  setMediaError: () => {},
  demoMode: false,
  setDemoMode: () => {},
  isRecording: false,
  setIsRecording: () => {},
  mediaAccessGranted: false,
  setMediaAccessGranted: () => {},
  isFirstTimeSetup: true,
  flowState: 'notStarted',
  setFlowState: () => {},
  loadAuscultationSession: () => new Promise((resolve) => resolve(true)),
  storedComment: undefined,
  nextSetupStepEnabled: true,
  setNextSetupStepEnabled: () => {},
  isNextGen: false,
  setRequirementIds: () => {},
  requirementIds: { auscultationRequirementIds: {}, commentRequirementId: undefined },
  saveUnsavedNextGenRecordings: () => new Promise((resolve) => resolve([]))
});

export const AuscultationProvider = ({ children }) => {
  const [audioInputDevice, setAudioInputDevice] = useState<string>();
  const [recordings, setRecordings] = useState({});
  const [timeSlice, setTimeSlice] = useState<number>(5000);
  const [patient, setPatientObj] = useState<Patient>();
  const [auscultationId, setId] = useState<string>();
  const [ecgFilename, setEcgFilenameLocal] = useState<string>();
  const [mediaError, setMediaError] = useState<unknown>();
  const [demoMode, setDemo] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaAccessGranted, setMediaAccessGranted] = useState(false);
  const [isFirstTimeSetup, setIsFirstTimeSetup] = useState(true);
  const [flowState, setFlowState] = useState<FlowState>('notStarted');
  const [storedComment, setStoredComment] = useState<string>();
  const [nextSetupStepEnabled, setNextSetupStepEnabled] = useState(true);
  const [isNextGen, setIsNextGen] = useState(false);
  const [requirementIds, setRequirementIds] = useState<AuscultationRequirements>({
    auscultationRequirementIds: {},
    commentRequirementId: undefined
  });

  useEffect(() => {
    localforage
      .getItem('demoMode')
      .then((value) => {
        setDemo(!!value);
        Mixpanel.trackingEnabled(!value);
        return localforage.getItem('auscultationId');
      })
      .then((id) => {
        if (id) {
          setId(id as string);
        }

        return localforage.getItem('patient');
      })
      .then((patient) => {
        if (patient) {
          const p: Patient = patient as Patient;
          setPatientObj(p);
          setIsNextGen(!!p.nextGeneration);
        }
        return localforage.getItem('recordings');
      })
      .then((recordings) => {
        if (recordings) {
          setRecordings(recordings as Record<string, StoredRecording>);
        }
        return localforage.getItem('ecgFilename');
      })
      .then((filename) => {
        if (filename) {
          setEcgFilenameLocal(filename as string);
        }
        return localforage.getItem('deviceId');
      })
      .then((id) => {
        if (id) {
          setAudioInputDevice(id as string);
          setIsFirstTimeSetup(false);
          setMediaAccessGranted(true); // Assume that if an audio input device id is stored, we still have access.
        }
        return localforage.getItem('storedComment');
      })
      .then((comment) => {
        if (comment) {
          setStoredComment(comment as string);
        }
        return localforage.getItem('requirementIds');
      })
      .then((requirementIds) => {
        if (requirementIds) {
          setRequirementIds(requirementIds as AuscultationRequirements);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const storeRecording = async (name: string, audioBlob: Blob, metadata: Record<string, any>) => {
    if (!metadata.isDemoRecording) {
      try {
        if (isNextGen && patient?.id && auscultationId) {
          await updateAuscultationObservation(
            patient.id,
            auscultationId,
            name,
            audioBlob,
            requirementIds.auscultationRequirementIds
          );
        } else {
          await storeAuscultationRecording(auscultationId!, name, audioBlob);
        }
        metadata = { ...metadata, savedToBackend: true };
      } catch (error) {
        metadata = { ...metadata, savedToBackend: false };
      }
    }

    const updated = {
      ...recordings,
      [name]: {
        blob: audioBlob,
        metadata: {
          ...metadata,
          type: name.startsWith('heart') ? 'heart' : 'lungs'
        }
      }
    };
    setRecordings(updated);

    if (demoMode) {
      return;
    }

    localforage.setItem('recordings', updated, () => {
      console.log('stored with localforage', updated);
    });
  };

  const getUnsavedRecordings = (): Record<string, any> => {
    const unsaved = Object.entries(recordings).filter((entry) => {
      const recording = entry[1] as StoredRecording;
      return recording?.metadata.savedToBackend === false && recording.blob;
    });

    return Object.fromEntries(unsaved);
  };

  const markAllRecordingsSaved = () => {
    const markedAsSaved = Object.entries(recordings).map((entry) => {
      const recording = entry[1] as StoredRecording;
      recording.metadata = { ...recording.metadata, savedToBackend: true };
      return [entry[0], recording];
    });

    const updatedRecordings = Object.fromEntries(markedAsSaved);
    setRecordings(updatedRecordings);
    localforage.setItem('recordings', updatedRecordings, () => {
      console.log('stored with localforage', updatedRecordings);
    });
  };

  const deleteRecording = (name: string) => {
    console.log('deleting recording', name);
    const updated = { ...recordings, [name]: undefined };
    setRecordings(updated);
    localforage.setItem('recordings', updated);
  };

  const hasRecording = (name: string) => {
    return !!recordings[name];
  };

  const hasRecordingOfType = (type: string) => {
    return Object.entries(recordings).some((entry) => {
      const recording = entry[1] as StoredRecording;
      return recording?.metadata.type === type && recording.blob;
    });
  };

  const allRecordingsOfTypeComplete = (type: string) => {
    const completedRecordings = Object.entries(recordings)
      .map((entry) => entry[1] as StoredRecording)
      .filter((rec: StoredRecording) => rec?.metadata.type === type && rec.blob);

    return completedRecordings.length === (type === 'heart' ? 4 : 6);
  };

  const getRecording = (name: string): StoredRecording => {
    return recordings[name];
  };

  const clearLocalData = () => {
    return new Promise((resolve) => {
      localforage
        .removeItem('patient')
        .then(() => localforage.removeItem('recordings'))
        .then(() => localforage.removeItem('ecgFilename'))
        .then(() => localforage.removeItem('auscultationId'))
        .then(() => localforage.removeItem('storedComment'))
        .then(() => localforage.removeItem('requirementIds'))
        .then(() => resolve(true));
    });
  };

  const clearRecordingData = () => {
    return new Promise((resolve) => {
      localforage
        .removeItem('patient')
        .then(() => localforage.removeItem('recordings'))
        .then(() => localforage.removeItem('ecgFilename'))
        .then(() => localforage.removeItem('storedComment'))
        .then(() => resolve(true));
    });
  };

  const setPatient = (patient: Patient, storeLocally = false) => {
    setPatientObj(patient);
    setIsNextGen(!!patient.nextGeneration);
    if (storeLocally) {
      localforage.setItem('patient', patient);
    }
  };

  const setAuscultationId = (id: string) => {
    setId(id);
    return localforage.setItem('auscultationId', id);
  };

  const setEcgFilename = (filename: string) => {
    setEcgFilenameLocal(filename);

    if (demoMode) {
      return;
    }

    localforage.setItem('ecgFilename', filename);
  };

  const setDevice = (deviceId) => {
    setAudioInputDevice(deviceId);
    localforage.setItem('deviceId', deviceId);
  };

  const setDemoMode = (enabled: boolean) => {
    setDemo(enabled);
    Mixpanel.trackingEnabled(!enabled);
    localforage.setItem('demoMode', enabled);
  };

  const loadAuscultationSession = async (auscultationSession: AuscultationSession): Promise<boolean> => {
    if (!auscultationSession.recordings?.length) {
      return Promise.resolve(false);
    }

    const sessionRecordings = {};
    await Promise.all(
      auscultationSession.recordings.map(async (recording) => {
        const audioData = await fetch(recording.value);
        const audioBlob = await audioData.blob();

        sessionRecordings[recording.key] = {
          blob: audioBlob,
          metadata: { savedToBackend: true, type: recording.key.includes('heart') ? 'heart' : 'lungs' }
        };
      })
    );

    await localforage.setItem('recordings', sessionRecordings);

    if (auscultationSession?.fileId) {
      await localforage.setItem('ecgFilename', auscultationSession.fileId);
    }

    if (auscultationSession?.comment) {
      await localforage.setItem('storedComment', auscultationSession.comment);
    }

    return true;
  };

  const setRequirements = (requirements: AuscultationRequirements) => {
    setRequirementIds(requirements);
    localforage.setItem('requirementIds', requirements);
  };

  const saveUnsavedNextGenRecordings = async (): Promise<Response[]> => {
    if (!patient?.id || !auscultationId) {
      throw new Error('Patient or auscultation missing');
    }

    const unsaved = getUnsavedRecordings();

    return Promise.all(
      Object.entries(unsaved).map((entry) => {
        return updateAuscultationObservation(
          patient.id!,
          auscultationId,
          entry[0],
          entry[1].blob,
          requirementIds.auscultationRequirementIds
        );
      })
    );
  };

  const value = {
    audioInputDevice,
    setAudioInputDevice: setDevice,
    recordings,
    storeRecording,
    getRecording,
    deleteRecording,
    hasRecording,
    hasRecordingOfType,
    allRecordingsOfTypeComplete,
    getUnsavedRecordings,
    markAllRecordingsSaved,
    timeSlice,
    setTimeSlice,
    clearLocalData,
    clearRecordingData,
    patient,
    setPatient,
    auscultationId,
    setAuscultationId,
    ecgFilename,
    setEcgFilename,
    mediaError,
    setMediaError,
    demoMode,
    setDemoMode,
    isRecording,
    setIsRecording,
    mediaAccessGranted,
    setMediaAccessGranted,
    isFirstTimeSetup,
    flowState,
    setFlowState,
    loadAuscultationSession,
    storedComment,
    nextSetupStepEnabled,
    setNextSetupStepEnabled,
    isNextGen,
    setRequirementIds: setRequirements,
    requirementIds,
    saveUnsavedNextGenRecordings
  };

  return <AuscultationContext.Provider value={value}>{children}</AuscultationContext.Provider>;
};
