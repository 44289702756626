import React, { useState } from 'react';
import { addUser as add, updateUser as update, removeUser as remove, updateFacility as _updateFacility } from 'api';
import { useAuth } from 'hooks/useAuth';

type ContextValue = {
  addUser(newUser: any, callback?: Function): void;
  updateUser(updatedUser: any, callback?: Function): void;
  removeUser(guid: string, callback?: Function): any;
  updateFacility(facilityId: string, updatedFacility: Facility, callback?: Function): void;
  addUserError?: unknown;
  updateUserError?: unknown;
  removeUserError?: unknown;
  addingUser: boolean;
  updatingUser: boolean;
  removingUser: boolean;
  updateFacilityError?: unknown;
  availableServices: PartnerServiceState[];
};

export type PartnerService = 'userAdmin' | 'partnerDataEntry' | 'recommendPatients' | 'auscultationFlow' | 'kiosk';
export const nonUserServices = ['kiosk', 'webScreening'];
export const nonFacilityServices = ['userAdmin'];

export const AdministrationContext = React.createContext<ContextValue>({
  addUser: () => {},
  updateUser: () => {},
  removeUser: () => {},
  updateFacility: () => {},
  addingUser: false,
  updatingUser: false,
  removingUser: false,
  availableServices: []
});

export const AdministrationProvider = ({ children }) => {
  const [addUserError, setAddUserError] = useState<unknown>();
  const [updateUserError, setUpdateUserError] = useState<unknown>();
  const [addingUser, setAddingUser] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [removeUserError, setRemoveUserError] = useState<unknown>();
  const [removingUser, setRemovingUser] = useState(false);
  const [updateFacilityError, setUpdateFacilityError] = useState<unknown>();
  const { session } = useAuth();

  const addUser = async (newUser: any, callback: Function) => {
    setAddUserError(undefined);
    setAddingUser(true);

    try {
      const response = await add(newUser);
      if (!response.ok) {
        throw new Error('Request failed');
      }
      callback();
    } catch (err) {
      setAddUserError(err);
    } finally {
      setAddingUser(false);
    }
  };

  const updateUser = async (updatedUser: any, callback: Function) => {
    setUpdateUserError(undefined);
    setUpdatingUser(true);

    try {
      const response = await update(updatedUser.guid, updatedUser);
      if (!response.ok) {
        throw new Error('Request failed');
      }

      callback();
    } catch (err) {
      setUpdateUserError(err);
    } finally {
      setUpdatingUser(false);
    }
  };

  const removeUser = async (guid: string, callback: Function) => {
    setRemoveUserError(undefined);
    setRemovingUser(true);

    try {
      const response = await remove(guid);
      if (!response.ok) {
        throw new Error('Request failed');
      }

      callback();
    } catch (err) {
      setRemoveUserError(err);
    } finally {
      setRemovingUser(false);
    }
  };

  const updateFacility = async (facilityId: string, updatedFacility: Facility, callback: Function) => {
    setUpdateFacilityError(undefined);

    try {
      const response = await _updateFacility(facilityId, updatedFacility);
      if (!response.ok) {
        throw new Error('Request failed');
      }

      callback();
    } catch (err) {
      setUpdateFacilityError(err);
    }
  };

  const value = {
    addUser,
    updateUser,
    removeUser,
    updateFacility,
    addUserError,
    updateUserError,
    removeUserError,
    addingUser,
    updatingUser,
    removingUser,
    updateFacilityError,
    availableServices:
      session?.user?.services?.filter((s) => !s.disabledByParent).map((s) => ({ ...s, selected: false })) || []
  };

  return <AdministrationContext.Provider value={value}>{children}</AdministrationContext.Provider>;
};
