import mixpanel from 'mixpanel-browser';
import environments from '../environments.json';

let _trackingEnabled = process.env.NODE_ENV === 'production';

export type TrackingEvent =
  | 'Flow|Begin|Auscultation'
  | 'Flow|Finish|Auscultation'
  | 'Flow|Begin|TestAuscultation'
  | 'Flow|Finish|TestAuscultation'
  | 'Action|DeleteRecording'
  | 'Action|ShowInstructionsAgain'
  | 'Action|SkipSetup'
  | 'Action|ChangeAudioInput'
  | 'Action|CancelAuscultation'
  | 'Action|UseKeyboard'
  | 'Action|SaveUnsavedRecordings'
  | 'Action|StartAuscultationDemoMode'
  | 'Action|AddEcgToExistingAuscultation'
  | 'Error|InvalidSession'
  | 'Error|MediaAccessDenied'
  | 'Error|Login';

const Mixpanel = {
  init: () => {
    mixpanel.init(
      environments[process.env.REACT_APP_ENV || 'development']?.mixpanelToken,
      { api_host: 'https://api-eu.mixpanel.com' },
      ''
    );
  },
  trackingEnabled: (isEnabled: boolean) => {
    _trackingEnabled = isEnabled;
  },
  identify: (id?: string) => {
    if (_trackingEnabled) {
      mixpanel.identify(id);
    } else {
      console.log('Identify', id);
    }
  },
  setProperty: (property: string, value: any) => {
    if (_trackingEnabled) {
      mixpanel.people.set(property, value);
    } else {
      console.log('Setting user property', property, value);
    }
  },
  setProperties: (properties: Record<string, JsonValue>) => {
    if (_trackingEnabled) {
      mixpanel.people.set(properties);
    } else {
      console.log('Setting user properties', properties);
    }
  },
  track: (name: TrackingEvent, props?: JsonValue) => {
    if (_trackingEnabled) {
      mixpanel.track(name, props);
    } else {
      console.log('Tracking event', name, props);
    }
  },
  timeEvent: (name: TrackingEvent) => {
    if (_trackingEnabled) {
      mixpanel.time_event(name);
    } else {
      console.log('Timing event', name);
    }
  },
  reset: () => {
    if (_trackingEnabled) {
      mixpanel.reset();
    }
  }
};

export default Mixpanel;
