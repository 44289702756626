import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <main className={styles.notfound}>
      <section>
        <h1>{t('page.not_found.header')}</h1>
        <div>
          <Link to="/">{t('page.not_found.back_to_start')}</Link>
        </div>
      </section>
    </main>
  );
}
