import headphonesImage from '../../../assets/headphones.png';

export default function HeadphoneSetup() {
  return (
    <>
      <img src={headphonesImage} width={480} height={180} alt="Hörlurar" />
      <div className="padded-content mt-1">
        <ol>
          <li>
            <strong>
              Koppla in ditt val av hörlurar. Du har fått ett par från oss, men du kan självfallet använda egna oavsett
              om de kopplas med kabel eller Bluetooth.
            </strong>
          </li>
          <li>
            Vi har även skickat med en förlängningssladd för både stetoskopet och hörlurarna som du kan ha nytta av om
            du behöver lite extra avstånd.
          </li>
          <li>
            Kontrollera att du har ljudet påslaget på din dator och tillräcklig volym. Vi efterjusterar inte volym.
          </li>
        </ol>
      </div>
    </>
  );
}
