import { NavLink } from 'react-router-dom';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';
import { ReactComponent as UserAdministrationIcon } from 'assets/icons/user-administration.svg';
import styles from './ServiceLinks.module.scss';
import { useAuth } from 'hooks/useAuth';
import { PartnerPermission } from 'typescript-partner-client';
import { useTranslation } from 'react-i18next';
import ProfileCard from 'components/ProfileCard';
import { AUSCULTATION_ONBOARDING_STATUS } from '../../../../constants';

export default function ServiceLinks() {
  const { t } = useTranslation();
  const { session } = useAuth();

  return (
    <div className={styles.wrapper}>
      <header>
        <ProfileCard
          user={session?.user}
          verified={session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED}
        />
      </header>
      <div className={styles.linksWrapper}>
        <ServiceLink path="/administration" icon={<UserAdministrationIcon />} supervisor>
          {t('page.dashboard.links.administration')}
        </ServiceLink>
        <ServiceLink
          path="https://blodtrycksdoktorn.se/hur-du-l%c3%a4gger-till-anv%c3%a4ndare-i-partnerportalen/"
          icon={<EducationIcon />}
          supervisor
        >
          {t('page.dashboard.links.administration_education')}
        </ServiceLink>
      </div>
    </div>
  );
}

const ServiceLink = ({ children, icon, path, supervisor = false }) => {
  const { authorities } = useAuth();
  const { t } = useTranslation();

  if (supervisor && !authorities.includes(PartnerPermission.USER_ADMIN)) {
    return null;
  }

  const content = (
    <>
      <div className="flex align-center">
        <span className={styles.linkIcon}>{icon}</span> {children}
      </div>
      {supervisor && <span className={styles.badge}>{t('page.dashboard.supervisor_badge')}</span>}
    </>
  );

  return (
    <div className={styles.link}>
      {path.startsWith('http') ? (
        <a href={path} target="_blank" rel="noreferrer noopener">
          {content}
        </a>
      ) : (
        <NavLink to={path}>{content}</NavLink>
      )}
    </div>
  );
};
