import FadeoutMessage from 'components/FadeoutMessage';
import StatusMessage from 'components/StatusMessage';
import { AdministrationProvider } from 'providers/AdministrationProvider';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Administration.module.scss';
import { useBackLink } from 'pages/Index';

export default function Administration() {
  const location = useLocation();
  const { backLink } = useBackLink();

  //@ts-expect-error
  const flashMessage = location?.state?.flashMessage;

  return (
    <div className={styles.wrapper}>
      {backLink}
      <h1>Administration</h1>
      <AdministrationProvider>
        <Outlet />
      </AdministrationProvider>
      {flashMessage ? (
        <div className={styles.flashMessage}>
          <FadeoutMessage>
            <StatusMessage status="success">{flashMessage}</StatusMessage>
          </FadeoutMessage>
        </div>
      ) : null}
    </div>
  );
}
