import { useEffect } from 'react';
import useDeviceList from 'hooks/useDeviceList';
import { useAuscultation } from 'hooks/useAuscultation';
import useAudioStream from 'hooks/useAudioStream';
import StatusMessage from 'components/StatusMessage';
import Mixpanel from 'services/tracking';
import { useTranslation } from 'react-i18next';
import stethoscopeConnection from '../../assets/stethoscope-connection.png';
import styles from './AudioInputList.module.scss';
import Notice from 'components/Notice';

export default function AudioInputList() {
  const { audioInputDevice, setAudioInputDevice, setNextSetupStepEnabled } = useAuscultation();
  const { mediaAccessGranted, endAudioStream } = useAudioStream();
  let { devices, acquiringDevices, error: devicesError } = useDeviceList('audioinput', !!mediaAccessGranted);

  const deviceList = devices
    ? devices.map((device, i) => ({
        id: device.deviceId,
        value: device.deviceId,
        label: device.label || `Mikrofon ${i + 1}`,
        isNew: device.isNew
      }))
    : [];

  const defaultSelectedDevice = deviceList.find((device) => device.id === audioInputDevice) || deviceList[0];

  useEffect(() => {
    setNextSetupStepEnabled(!!mediaAccessGranted);

    if (defaultSelectedDevice?.id) {
      setAudioInputDevice(defaultSelectedDevice.id);
    }

    if (mediaAccessGranted) {
      endAudioStream();
    }

    return () => {
      endAudioStream();
    };
  }, [mediaAccessGranted, defaultSelectedDevice?.id]); // eslint-disable-line

  const selectInput = (deviceId: string) => {
    setAudioInputDevice(deviceId);
    Mixpanel.track('Action|ChangeAudioInput');
  };

  return (
    <>
      <img
        src={stethoscopeConnection}
        className="border-radius-0"
        width={480}
        height={180}
        alt="Koppla in stetoskopet"
      />
      <div className={styles.wrapper}>
        <div>Koppla in stetoskopet i datorn.</div>
        <h4>Välj ljudingång</h4>
        {devicesError || mediaAccessGranted === false || (!acquiringDevices && !deviceList.length) ? (
          <StatusMessage status="error">
            {!devices.filter((device) => !!device.deviceId).length ? 'Hittade inga ljudingångar' : 'Mediastöd saknas'}
          </StatusMessage>
        ) : (
          <>
            <div className={styles.deviceListWrapper}>
              {deviceList.map((device) => (
                <InputDeviceRow
                  key={device.id}
                  label={device.label}
                  isNew={device.isNew}
                  onClick={() => selectInput(device.id)}
                  isSelected={audioInputDevice === device.id}
                />
              ))}
            </div>
            <Notice>
              Om du inte hittar stetoskopet i listan över ljudingångar, dra ut sladden och koppla in den igen. Systemet
              kommer då automatiskt att välja stetoskopet som ljudingång.
            </Notice>
          </>
        )}
      </div>
    </>
  );
}

const InputDeviceRow = ({ isSelected, isNew, label, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.inputRow} ${isSelected ? styles.deviceSelected : ''}`} onClick={onClick}>
      {isNew && <span className={styles.newDevice}>{t('global.new')}</span>}
      {label}
    </div>
  );
};
