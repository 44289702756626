import { decamelize } from 'utils';
import { useRef, useState, useEffect } from 'react';
import { useAuscultation } from 'hooks/useAuscultation';
import { NavLink } from 'react-router-dom';
import styles from './RecordingRow.module.scss';
import { useTranslation } from 'react-i18next';
import useAudioFileVisualization from 'hooks/useAudioFileVisualization';
import { LUNG_AUSCULTATION_DURATION, HEART_AUSCULTATION_DURATION } from '../../../../constants';
import AudioPlayback from 'pages/Auscultation/AudioPlayback';

type RecordingRowProps = {
  recordingType?: 'heart' | 'lungs';
  location?: string;
  index?: number;
  audioBlob?: Blob;
  header?: string;
  hasAudioMetadata?: boolean;
  duration?: number;
};

export default function RecordingRow({
  recordingType,
  location,
  index,
  audioBlob,
  header,
  hasAudioMetadata = true,
  duration
}: RecordingRowProps) {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const { getRecording } = useAuscultation();
  const audioRef = useRef<HTMLMediaElement | null>(null);
  const recording = getRecording(`${recordingType}${location}`);
  const { canvasRef } = useAudioFileVisualization(audioBlob ?? recording?.blob);
  const objectUrlRef = useRef<string>();
  const recordingHeader =
    header ?? `${index}. ${t(`page.auscultation.${recordingType}.recording_location.${decamelize(location!)}`)}`;
  const blob = recording?.blob?.size ? recording.blob : audioBlob;

  useEffect(() => {
    if (blob) {
      objectUrlRef.current = window.URL.createObjectURL(blob);
    }

    return () => {
      if (objectUrlRef.current) {
        window.URL.revokeObjectURL(objectUrlRef.current);
      }
    };
  }, [blob]);

  const handleAudioEnd = () => {
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.removeEventListener('ended', handleAudioEnd);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
      if (hasAudioMetadata) {
        audioRef.current.addEventListener('ended', handleAudioEnd);
      } else {
        setTimeout(handleAudioEnd, duration);
      }
    }

    return () => clearTimeout(timeout);
  }, [isPlaying]); // eslint-disable-line

  const play = () => {
    setIsPlaying(true);
  };

  const stop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>{recordingHeader}</div>
        {index ? (
          <NavLink to={`/auskultation/flow/${recordingType}?location=${index}`} className={styles.editButton}>
            Edit
          </NavLink>
        ) : null}
      </div>
      <AudioPlayback
        duration={
          (duration ? duration : recordingType === 'heart' ? HEART_AUSCULTATION_DURATION : LUNG_AUSCULTATION_DURATION) /
          1000
        }
        isPlaying={isPlaying}
        playable={!!blob}
        onPlay={play}
        onStop={stop}
      >
        {blob ? (
          <canvas key={header || `${recordingType}-${location}`} height={50} ref={canvasRef} />
        ) : (
          <div className={styles.recordingMissing}>Ingen inspelning</div>
        )}
      </AudioPlayback>
      <audio ref={audioRef} src={objectUrlRef.current} controls={false}></audio>
    </div>
  );
}
