import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useAuth } from 'hooks/useAuth';
import { isValidSession } from 'utils';
import ErrorFallback from 'components/ErrorFallback';
import Mixpanel from 'services/tracking';

export const ProtectedRoute = () => {
  const { session, sessionTimedOut } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  if (!isValidSession(session)) {
    if (location.pathname !== '/') {
      Mixpanel.track('Error|InvalidSession', { path: location.pathname, sessionTimedOut });
    }

    return (
      <Navigate
        to="/login"
        replace
        state={{ from: location, message: sessionTimedOut ? t('page.login.inactivity_logout') : '' }}
      />
    );
  }

  return (
    <ErrorBoundary fallback={(fallbackProps) => <ErrorFallback {...fallbackProps} />}>
      <Outlet />
    </ErrorBoundary>
  );
};
