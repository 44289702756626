import { ReactNode } from 'react';
import styles from './List.module.scss';

type ListProps<T> = {
  items: T[];
  columnHeaders: ReactNode;
  renderItem: (item: T) => ReactNode;
  keyExtractor: (item: T) => string | number;
  fallback?: string;
};

export default function List<T extends unknown>({
  items,
  columnHeaders,
  fallback,
  renderItem,
  keyExtractor
}: ListProps<T>) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.columnHeaders}>{columnHeaders}</div>
      {items.length ? (
        items.map((item) => (
          <div key={keyExtractor(item)} className={styles.row}>
            {renderItem(item)}
          </div>
        ))
      ) : fallback ? (
        <div className={`${styles.row} ${styles.fallback}`}>{fallback}</div>
      ) : null}
    </div>
  );
}
