import ServiceDashboardItem from './ServiceDashboardItem';
import { useAuth } from 'hooks/useAuth';
import styles from './Dashboard.module.scss';
import ServiceLinks from './ServiceLinks';

export default function Dashboard() {
  const { authorities } = useAuth();
  const services = ['auscultationFlow', 'partnerDataEntry', 'recommendPatients'];

  return (
    <>
      <div className={`${styles.servicesWrapper} ${authorities.length === 1 ? styles.single : ''}`}>
        {services
          .filter((s) => authorities.includes(s))
          .map((authority, i) => (
            <ServiceDashboardItem key={i} requiredAuthority={authority} />
          ))}
      </div>
      <ServiceLinks />
    </>
  );
}
