import { useState } from 'react';
import { motion } from 'framer-motion';
import BlodtrycksdoktornLogo from 'assets/blodtrycksdoktorn.svg';
import { ReactComponent as BankIdLogo } from 'assets/bankid-logo-white.svg';
import { MotionButton } from 'components/Button';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';
import styles from './LoginForm.module.scss';
import { useAuth } from 'hooks/useAuth';
import Spinner from 'components/Spinner';

export default function LoginForm({ devMode, message }) {
  const { onLogin, error, beginLoginFlow, activeQrCode, cancelLogin, loginState } = useAuth();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleDevLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    onLogin?.(email, password, true);
  };

  return (
    <div className={styles.loginForm}>
      <motion.header
        layout={true}
        transition={{ type: 'tween' }}
        className={loginState === 'polling' ? styles.bg : undefined}
      >
        <img src={BlodtrycksdoktornLogo} alt="Blodtrycksdoktorn" />
      </motion.header>
      {!devMode ? (
        <>
          {loginState === 'error' ? <ErrorMessage error={error} i18nScope="page.login" /> : null}

          {loginState === 'idle' ? (
            <MotionButton
              size="large"
              Icon={BankIdLogo}
              onClick={beginLoginFlow}
              fullWidth={true}
              layoutId="mainButton"
            >
              {t('page.login.login_bankid')}
            </MotionButton>
          ) : null}

          <motion.div
            className={activeQrCode ? '' : 'hidden'}
            animate={{
              opacity: activeQrCode ? 1 : 0,
              scale: activeQrCode ? 1 : 0
            }}
            transition={{ type: 'tween' }}
          >
            {loginState === 'polling' && (
              <>
                <div className="flex justify-center" dangerouslySetInnerHTML={{ __html: activeQrCode || '' }} />
                <div>
                  Starta BankID-appen i din mobil och tryck på <strong>Skanna QR-kod</strong>. Rikta kameran mot
                  QR-koden här ovanför.
                </div>
              </>
            )}

            {loginState === 'user_signing' && (
              <div>
                <p>
                  Skriv in din säkerhetskod i BankID-appen och välj <strong>Identifiera</strong>.
                </p>
                <div className="flex justify-center">
                  <Spinner />
                </div>
              </div>
            )}
          </motion.div>

          {loginState === 'polling' ? (
            <MotionButton variant="secondary" onClick={cancelLogin} fullWidth layoutId="mainButton">
              Avbryt inloggning
            </MotionButton>
          ) : null}

          {loginState === 'error' ? (
            <div>
              Något gick fel.
              <MotionButton variant="secondary" onClick={cancelLogin} fullWidth layoutId="mainButton">
                Försök igen
              </MotionButton>
            </div>
          ) : null}

          {message ? <div className={styles.logoutMessage}>{message}</div> : null}
        </>
      ) : (
        <>
          <form onSubmit={handleDevLogin}>
            <div>
              <label htmlFor="portal-email">{t('global.email')}</label>
              <input type="text" id="portal-email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <label htmlFor="password">{t('global.password')}</label>
              <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              <ErrorMessage error={error} i18nScope="page.login" />
            </div>
            <MotionButton type="submit" size="large" fullWidth layoutId="mainButton">
              {t('page.login.login')}
            </MotionButton>
          </form>
          {message ? <div className={styles.logoutMessage}>{message}</div> : null}
        </>
      )}
    </div>
  );
}
