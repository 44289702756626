import { useState, useRef, useEffect, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Patient } from 'typescript-partner-client';
import ErrorMessage from 'components/ErrorMessage';
import styles from './PersonalIdentityNumberVerification.module.scss';
import { getAgeFromPersonalNumber, getGenderFromPersonalNumber } from 'utils';

type PersonalIdentityNumberVerificationProps = {
  onError: (err: unknown) => void;
  onReset: RefObject<Function>;
  apiMethod: (param: any) => any;
  showName?: boolean;
  errorI18nScope?: string;
  highlightError?: boolean;
  onApiResponse?: (response: any) => void;
  onInvalidInput?: () => void;
  prefilledPersonalNumber?: string;
};

export default function PersonalIdentityNumberVerification({
  onError,
  onReset,
  apiMethod,
  showName = true,
  errorI18nScope = 'page.ecg_upload.patient_lookup',
  highlightError = false,
  onApiResponse,
  onInvalidInput,
  prefilledPersonalNumber
}: PersonalIdentityNumberVerificationProps) {
  let debounceTimer = useRef<NodeJS.Timeout>();
  const debounceDelay = 750;
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);
  const [fetchingPatientName, setFetchingPatientName] = useState(false);
  const [patientNameError, setPatientNameError] = useState<unknown>();
  const [patientName, setPatientName] = useState<Patient>();
  const [personalNumber, setPersonalNumber] = useState<string>();

  useEffect(() => {
    // @ts-ignore
    onReset.current = reset;

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [onReset]);

  const reset = () => {
    setPatientName(undefined);
    setPatientNameError('');
  };

  const debouncedNameFetch = useCallback((delay?: number) => {
    const personalIdentityNumberRegex = /^\d{6,8}-?\d{4}$/;

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    const personalIdentityNumber = input?.current?.value || '';

    debounceTimer.current = setTimeout(async () => {
      setFetchingPatientName(true);
      setPatientNameError(undefined);
      setPatientName(undefined);

      try {
        if (personalIdentityNumber && personalIdentityNumberRegex.test(personalIdentityNumber)) {
          setPersonalNumber(personalIdentityNumber.replace('-', ''));
          const apiResponse = await apiMethod(personalIdentityNumber.replace('-', ''));
          setPatientName(apiResponse);
          onError(undefined);
          onApiResponse && onApiResponse(apiResponse);
        } else {
          onInvalidInput && onInvalidInput();
        }
      } catch (err: unknown) {
        setPatientNameError(err);
        onError(err);
      } finally {
        setFetchingPatientName(false);
      }
    }, delay ?? debounceDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prefilledPersonalNumber) {
      debouncedNameFetch(0);
    }
  }, [debouncedNameFetch, prefilledPersonalNumber]);

  return (
    <div>
      <label htmlFor="personal-number">{t('global.personal_identity_number')}</label>
      <input
        ref={input}
        type="text"
        id="personal-number"
        placeholder={t('global.personal_identity_number_format')}
        pattern="^\d{6,8}-?\d{4}$"
        name="personalIdentityNumber"
        autoComplete="off"
        required
        defaultValue={prefilledPersonalNumber}
        className={
          fetchingPatientName ? 'icon loading' : patientName ? 'icon success' : patientNameError ? 'icon error' : ''
        }
        onChange={() => debouncedNameFetch()}
      />
      {showName ? (
        <PatientInfo
          name={patientName ? `${patientName?.givenName} ${patientName?.familyName}` : undefined}
          age={personalNumber ? getAgeFromPersonalNumber(personalNumber) : undefined}
          gender={personalNumber ? getGenderFromPersonalNumber(personalNumber) : undefined}
        />
      ) : null}
      <ErrorMessage error={patientNameError} i18nScope={errorI18nScope} highlight={highlightError} />
    </div>
  );
}

const PatientInfo = ({ name, age, gender }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.patientInfo}>
      <div className={`${styles.patientAvatar} ${name && age ? styles[gender] : ''} ${!name ? styles.empty : ''}`}>
        {name && name[0]}
      </div>
      <div>
        {name && age ? (
          <>
            <span className={styles.patientName}>{name}</span>
            <span className={styles.patientGenderAge}>
              {t(`global.gender.${gender}`)}, {age} {t('global.years')}
            </span>
          </>
        ) : (
          <>
            <span className={styles.skeleton} style={{ width: '100px' }}></span>
            <span className={styles.skeleton} style={{ width: '50px' }}></span>
          </>
        )}
      </div>
    </div>
  );
};
