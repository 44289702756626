import { useAuth } from 'hooks/useAuth';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PartnerPermission } from 'typescript-partner-client';
import { ReactComponent as AuscultationIcon } from 'assets/icons/dashboard-auscultation.svg';
import { ReactComponent as RecommendIcon } from 'assets/icons/dashboard-recommendation.svg';
import { ReactComponent as ExaminationIcon } from 'assets/icons/dashboard-examination.svg';
import { ReactComponent as RecordIcon } from 'assets/icons/auscultation.svg';
import { ReactComponent as CurvedArrowIcon } from 'assets/icons/curved-arrow.svg';
import { ReactComponent as ListeningIcon } from 'assets/icons/listening.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';
import styles from './ServiceDashboardItem.module.scss';
import { AUSCULTATION_ONBOARDING_STATUS } from '../../../../constants';

type DashboardItemConfig = {
  path: string;
  title: string;
  icon: React.ReactNode;
  description: string;
  actionLinks: DashboardActionLink[];
  notice?: React.ReactNode;
};

type DashboardActionLink = {
  text: string;
  path: string;
  icon: React.ReactNode;
  disabled?: boolean;
};

export default function ServiceDashboardItem({ requiredAuthority }) {
  const { authorities, session } = useAuth();
  const { t } = useTranslation();

  const getAuscultationNotice = (auscultationOnboardingStatus) => {
    switch (auscultationOnboardingStatus) {
      case AUSCULTATION_ONBOARDING_STATUS.PENDING:
        return (
          <DashboardItemNotice type="pending">
            <p>
              <strong>Din provinspelning väntar på granskning</strong>
            </p>
            <p>
              Vårdpersonal hos Blodtrycksdoktorn kommer att granska provinspelningen och återkopplar via mejl inom några
              dagar.
            </p>
          </DashboardItemNotice>
        );
      case AUSCULTATION_ONBOARDING_STATUS.ACCEPTED:
        return null;
      case AUSCULTATION_ONBOARDING_STATUS.REJECTED:
        return (
          <DashboardItemNotice type="rejected">
            <p>
              <strong>Din provinspelning blev inte godkänd</strong>
            </p>
            <p>
              Eftersom din provinspelning inte blev godkänd behöver du göra om den innan du kan genomföra utredningar på
              patienter.
            </p>
            <p className="mb-0">Se kommentar nedan från vårdpersonal hos Blodtrycksdoktorn.</p>
            <blockquote>{session?.user?.properties?.auscultationOnboardingComment}</blockquote>
            <p>
              <span className="fake-link">Gör en ny provinspelning</span>
            </p>
          </DashboardItemNotice>
        );
      default:
        return (
          <DashboardItemNotice type="initial">
            <p>
              <strong>Du behöver göra en provinspelning</strong>
            </p>
            <p>
              För att göra utredningar med digitalt stetoskop behöver du först göra en provinspelning. Provinspelningar
              verifieras av Blodtrycksdoktorn för att säkerställa att kvaliteten är bedömningsbar.
            </p>
            <p>
              <span className="fake-link">Starta provinspelning</span>
            </p>
          </DashboardItemNotice>
        );
    }
  };

  function getItemContent(authority: PartnerPermission): DashboardItemConfig {
    let path = '';
    let title = '';
    let description = '';
    let actionLinks = [] as DashboardActionLink[];
    let notice;
    let icon = <></>;

    switch (authority) {
      case PartnerPermission.AUSCULTATION_FLOW: {
        path = '/auskultation';
        title = t('page.auscultation.header.accepted');
        icon = <AuscultationIcon />;
        description = t('page.auscultation.description.accepted');
        notice = getAuscultationNotice(session?.user?.properties?.auscultationOnboarding);
        actionLinks = [
          {
            text: t('page.dashboard.service.auscultation.actions.start_new'),
            path: '/auskultation',
            icon: <RecordIcon />,
            disabled: session?.user?.properties?.auscultationOnboarding !== 'accepted'
          },
          {
            text: 'Mina utredningar',
            path: '/auskultation/list',
            icon: <ListIcon />
          },
          {
            text: 'Utbildningsmaterial för utredning',
            path: '/auskultation/utbildning',
            icon: <EducationIcon />
          }
        ];
        break;
      }
      case PartnerPermission.RECOMMEND_PATIENTS: {
        path = '/hanvisning';
        title = t('page.recommend.header');
        icon = <RecommendIcon />;
        description = t('page.recommend.description');
        actionLinks = [
          {
            text: t('page.dashboard.service.recommendation.actions.recommend'),
            path: '/hanvisning',
            icon: <CurvedArrowIcon />
          },
          {
            text: 'Utbildningsmaterial för hänvisning',
            path: '/hanvisning/utbildning',
            icon: <EducationIcon />
          }
        ];
        break;
      }
      case PartnerPermission.PARTNER_DATA_ENTRY:
        path = '/ekg';
        title = t('page.ecg_upload.header');
        icon = <ExaminationIcon />;
        description = t('page.ecg_upload.description');
        actionLinks = [
          {
            text: t('page.dashboard.service.data_entry.actions.examination'),
            path: '/ekg',
            icon: <ListeningIcon />
          }
        ];
        break;
      case PartnerPermission.USER_ADMIN:
        break;
      default:
        throw new Error('Unknown authority');
    }

    return {
      path,
      title,
      icon,
      description,
      actionLinks,
      notice
    };
  }

  const { path, title, icon, description, actionLinks, notice } = getItemContent(requiredAuthority);

  if (!authorities.includes(requiredAuthority) || !path) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <NavLink to={path}>
        <div className={styles.itemHeader}>
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div>{icon}</div>
        </div>
        {notice && notice}
      </NavLink>

      <div className={styles.actionWrapper}>
        {actionLinks.map((link, i) => (
          <NavLink to={link.path} key={i} className={link.disabled ? styles.disabled : ''}>
            <span className={styles.actionLink}>
              {link.text}
              {link.icon}
            </span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

const DashboardItemNotice = ({ children, type }) => {
  return <div className={`${styles.notice} ${styles[type]}`}>{children}</div>;
};
