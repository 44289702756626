import { motion } from 'framer-motion';
import { ReactComponent as VerifiedBadge } from 'assets/icons/verified-badge.svg';
import styles from './ProfileCard.module.scss';

type ProfileCardProps = {
  user?: User;
  bounce?: boolean;
  verified?: boolean;
  open?: boolean;
};

const variants = {
  hover: {
    scale: 1.1,
    transition: { duration: 1, type: 'spring', bounce: 0.8 }
  },
  open: {
    scale: 1.1,
    rotateZ: 30,
    transition: { duration: 1, type: 'spring', bounce: 0.8 }
  }
};

export default function ProfileCard({ user, bounce, verified, open }: ProfileCardProps) {
  const unitName = user?.facilityName || user?.partnerName;

  return (
    <motion.div className={styles.profileCard} whileHover={bounce && !open ? 'hover' : undefined}>
      <motion.div className={styles.avatar} variants={variants} animate={open ? 'open' : undefined}></motion.div>
      <>
        {user ? (
          <div>
            <div className={styles.username}>
              <span>{`${user.givenName} ${user.familyName}`}</span>
              {verified ? <VerifiedBadge style={{ marginLeft: '5px' }} /> : null}
            </div>
            {unitName ? <div className="text-left">{unitName}</div> : null}
          </div>
        ) : null}
      </>
    </motion.div>
  );
}
