import { useState } from 'react';

export default function useSteps(totalSteps: number, onFinish: Function) {
  const [currentStep, setCurrentStep] = useState(1);
  const [direction, setDirection] = useState<'right' | 'left'>('left');

  const nextStep = () => {
    if (currentStep === totalSteps) {
      onFinish();
      return;
    }

    setDirection('right');
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setDirection('left');
      setCurrentStep(currentStep - 1);
    }
  };

  return { currentStep, totalSteps, direction, nextStep, previousStep };
}
