import useSWR from 'swr';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SkeletonLoader from 'components/SkeletonLoader';
import StatusMessage from 'components/StatusMessage';
import { ReactComponent as AddUserIcon } from 'assets/icons/add-user.svg';
import styles from './Users.module.scss';
import { useTranslation } from 'react-i18next';
import List from 'components/List';
import { fetcher } from 'api';
import { useAuth } from 'hooks/useAuth';
import { nonUserServices } from 'providers/AdministrationProvider';

type Props = {
  facilityId?: string;
};

export default function Users({ facilityId }: Props) {
  const { session } = useAuth();
  const {
    data: users = [],
    isLoading: fetchingUsers,
    error: userListError
  } = useSWR<User[]>(`/partner/user${facilityId ? `?facilityId=${facilityId}` : ''}`, fetcher);
  const NUM_SKELETON_ROWS = 3;

  useEffect(() => {
    // Clear away any flash messages stored in location state
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <div>
      <h2>
        {!facilityId ? `Globala användare för ${session?.user?.partnerName}` : 'Användare'}{' '}
        {fetchingUsers || !users.length ? null : <span className="heading-count">({users.length})</span>}
      </h2>
      <div className="table-header">
        <div>{!facilityId ? 'Inte anslutna till någon specifik mottagning.' : 'Anslutna till mottagningen.'}</div>
        <div>
          <AddUserIcon />
          <NavLink to="users/new">Lägg till användare</NavLink>
        </div>
      </div>
      {fetchingUsers ? (
        <>
          {new Array(NUM_SKELETON_ROWS).fill(0).map((_, i) => (
            <SkeletonLoader key={i} height="44px" />
          ))}
        </>
      ) : userListError ? (
        <StatusMessage status="error">Det gick inte att hämta användarlistan.</StatusMessage>
      ) : users.length ? (
        <List
          items={users}
          keyExtractor={(i) => i.guid!}
          renderItem={(item) => <UserRow user={item} />}
          columnHeaders={
            <>
              <div className="column name">Namn</div>
              <div className="column email">E-postadress</div>
              <div>Rättigheter</div>
            </>
          }
        />
      ) : (
        <div>Inga användare</div>
      )}
    </div>
  );
}

const UserRow = ({ user }) => {
  const { t } = useTranslation();
  const enabledServices = user.services?.filter((s) => s.selected && !nonUserServices.includes(s.service)) ?? [];
  const concatenatedServices = enabledServices.length
    ? enabledServices.map((service) => t(`global.permission.${service.service}`)).join(', ')
    : t('global.no_permissions');

  return (
    <NavLink to={`user/${user.guid}`}>
      <div className="column name">
        {user.givenName} {user.familyName}
      </div>
      <div className="column email">{user.email || '-'}</div>
      <div className="column services" title={concatenatedServices}>
        <span className={!enabledServices.length ? styles.servicesMissing : ''}>{concatenatedServices}</span>
      </div>
    </NavLink>
  );
};
