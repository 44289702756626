export const CANVAS_SETTINGS = {
  backgroundColor: 'rgb(249, 249, 249)',
  lineColor: 'rgb(18, 74, 159)'
};

export const HEART_AUSCULTATION_DURATION = 5_000;
export const LUNG_AUSCULTATION_DURATION = 10_000;

export const HEART_RECORDING_LOCATIONS = ['TopRight', 'TopLeft', 'BottomRight', 'BottomLeft'];
export const LUNG_RECORDING_LOCATIONS = ['TopLeft', 'TopRight', 'MidLeft', 'MidRight', 'BottomLeft', 'BottomRight'];

export const AUSCULTATION_ONBOARDING_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected'
};
