import localforage from 'localforage';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { AuscultationProvider } from 'providers/AuscultationProvider';
import { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAuscultation } from 'hooks/useAuscultation';
import { storeAuscultationRecordings } from 'api';
import Mixpanel from 'services/tracking';
import DemoBanner from 'components/DemoBanner';
import styles from './HeartLungAuscultationFlow.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { AUSCULTATION_ONBOARDING_STATUS } from '../../../constants';
import NavBarMenu from 'pages/Index/NavBar/NavBarMenu';

export default function HeartLungAuscultationFlow() {
  return (
    <AuscultationProvider>
      <HeartLungAuscultationFlowContent />
    </AuscultationProvider>
  );
}

const HeartLungAuscultationFlowContent = () => {
  const navigate = useNavigate();
  const {
    clearLocalData,
    demoMode,
    setDemoMode,
    setFlowState,
    getUnsavedRecordings,
    auscultationId,
    isNextGen,
    saveUnsavedNextGenRecordings,
    patient
  } = useAuscultation();
  const [cancelationModalActive, setCancelationModalActive] = useState(false);
  const { t } = useTranslation();
  const { session } = useAuth();

  useEffect(() => {
    let demo = false;
    setFlowState('inProgress');

    localforage
      .getItem('demoMode')
      .then((value) => {
        demo = !!value;
        return localforage.getItem('auscultationId');
      })
      .then((id) => {
        if (!id && !demo) {
          navigate('/auskultation', { replace: true });
        }
      });
  }, [navigate, setFlowState]);

  const confirmCancelation = async () => {
    if (demoMode) {
      await clearLocalData();
      navigate('/auskultation');
      return;
    }

    if (!auscultationId) {
      throw new Error('Auscultation ID missing');
    }

    setFlowState('notStarted');
    const unsavedRecordings = getUnsavedRecordings();

    try {
      if (Object.keys(unsavedRecordings).length) {
        if (isNextGen) {
          await saveUnsavedNextGenRecordings();
        } else {
          await storeAuscultationRecordings(auscultationId, unsavedRecordings);
        }
      }
      await clearLocalData();
      navigate('/auskultation');
      Mixpanel.track('Action|CancelAuscultation');
    } catch (err) {
      // Todo: hantera fel här
      console.log(err);
    }
  };

  const exitAuscultation = async () => {
    if (demoMode) {
      setFlowState('notStarted');
      setDemoMode(false);
      await confirmCancelation();
    } else {
      setCancelationModalActive(true);
    }
  };

  return (
    <>
      <DemoBanner />
      <header className={styles.header}>
        <div className={styles.logo} />
        <nav className={styles.nav}>
          <div className={styles.line}></div>
          <ul>
            <li>
              <NavLink to="/auskultation/flow/heart" className={(navData) => (navData.isActive ? styles.active : '')}>
                <span>1</span>Hjärtauskultation
              </NavLink>
            </li>
            <li>
              <NavLink to="/auskultation/flow/lungs" className={(navData) => (navData.isActive ? styles.active : '')}>
                <span>2</span>Lungauskultation
              </NavLink>
            </li>
            <li>
              <NavLink to="/auskultation/flow/ekg" className={(navData) => (navData.isActive ? styles.active : '')}>
                <span>3</span>EKG-uppladdning
              </NavLink>
            </li>
            <li>
              <NavLink to="/auskultation/flow/summary" className={(navData) => (navData.isActive ? styles.active : '')}>
                <span>4</span>Sammanställning
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={styles.menuButtons}>
          <Button variant="tertiary" onClick={exitAuscultation}>
            {t(
              `page.auscultation.exit.${
                demoMode
                  ? 'demo_mode'
                  : session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                  ? 'assessment'
                  : 'standard'
              }`
            )}
          </Button>
          <NavBarMenu />
        </div>
      </header>
      <div className={styles.subHeader}>
        <div>
          {patient?.givenName} {patient?.familyName}
        </div>
        <div>{patient?.personalNumber}</div>
      </div>
      <main className={styles.contentWrapper}>
        <Outlet />
      </main>
      <Modal
        title="Helt säker?"
        actionText={t(
          `page.auscultation.exit.modal.action.${
            session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
              ? 'assessment'
              : 'standard'
          }`
        )}
        isOpen={cancelationModalActive}
        onClose={() => setCancelationModalActive(false)}
        onAction={confirmCancelation}
        size="small"
      >
        {t(
          `page.auscultation.exit.modal.content.${
            session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
              ? 'assessment'
              : 'standard'
          }`
        )}
      </Modal>
    </>
  );
};
