import styles from './Notice.module.scss';

type NoticeProps = {
  children: React.ReactNode;
  narrow?: boolean;
};

export default function Notice({ children, narrow }: NoticeProps) {
  return <div className={`${styles.wrapper} ${narrow ? styles.narrow : ''}`}>{children}</div>;
}
