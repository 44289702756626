import Button from 'components/Button';
import ProfileCard from 'components/ProfileCard';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Popover from 'components/Popover';
import styles from './NavBarMenu.module.scss';

export default function NavBarMenu() {
  const { session, onLogout } = useAuth();
  const { t } = useTranslation();

  return (
    <Popover trigger={(open: boolean) => <ProfileCard user={session?.user} bounce open={open} />}>
      <>
        <section className={styles.popoverContent}>
          <Button variant="tertiary" onClick={onLogout} fullWidth>
            {t('global.logout')}
          </Button>
        </section>
        <section className={styles.popoverFooter}>
          <h4>Support</h4>
          <div className="stack-0">
            <div>
              <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a>
            </div>
            <div className="mt-10">
              0480-797 960
              <br />
              <small>(vardagar mellan kl. 9–11)</small>
            </div>
          </div>
        </section>
      </>
    </Popover>
  );
}
