import { useState } from 'react';
import packageJson from '../../../package.json';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import { useAuth } from '../../hooks/useAuth';
import styles from './Login.module.scss';
import { useEffect } from 'react';
import { isValidSession } from '../../utils';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const [devLogin, setDevLogin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (isValidSession(session)) {
      navigate('/');
    }
  }, [navigate, session]);

  //@ts-expect-error
  const message = location?.state?.message;

  return (
    <>
      <div className={styles.logo}></div>
      <section className={styles.loginCenterContent}>
        <div>
          <LoginForm devMode={devLogin} message={message} />
        </div>
      </section>
      <footer className={styles.loginFooter}>
        <strong>Blodtrycksdoktorn AB</strong>
        <span>{t('page.login.footer.address')}</span>
        <div className={styles.version}>
          Version:{' '}
          <span onClick={process.env.REACT_APP_ENV !== 'production' ? () => setDevLogin(!devLogin) : undefined}>
            {packageJson.version}
          </span>
        </div>
      </footer>
    </>
  );
}
