import { AnimatePresence, motion } from 'framer-motion';
import { decamelize } from 'utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuscultation } from 'hooks/useAuscultation';
import useAudioRecorder from 'hooks/useAudioRecorder';
import useWaveFormVisualization from 'hooks/useWaveFormVisualization';
import useAudioFileVisualization from 'hooks/useAudioFileVisualization';
import Button from 'components/Button';
import { ReactComponent as RecordIcon } from '../../assets/icons/record.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import styles from './AudioRecorder.module.scss';
import Mixpanel from 'services/tracking';
import useOscilloscopeVisualization from 'hooks/useOscilloscopeVisualization';

type AudioRecorderProps = {
  location?: string;
  recordingType: 'heart' | 'lungs';
  isActive: boolean;
  onNext?(e: any): void;
  onPrevious?(e: any): void;
  onClick?(): void;
  order?: number;
  audioStream?: MediaStream;
  mediaSource?: AudioNode;
  audioDestination?: AudioDestinationNode;
};

export default function AudioRecorder({
  location = '',
  recordingType,
  isActive,
  onNext,
  onPrevious,
  onClick,
  order,
  audioStream: oscilloscopeStream,
  mediaSource,
  audioDestination
}: AudioRecorderProps) {
  const name = recordingType + location;
  const { t } = useTranslation();
  const audioElementRef = useRef<HTMLMediaElement | null>(null);
  const { stopRecording, startRecording, clearMediaBlob, audioBlob, audioStream, status } = useAudioRecorder();
  const { storeRecording, deleteRecording, hasRecording, getRecording, timeSlice, demoMode, isRecording } =
    useAuscultation();
  const {
    canvasRef: waveform,
    clearCanvas,
    stopVisualization,
    startVisualization
  } = useWaveFormVisualization(audioStream, timeSlice / 1000);
  const { canvasRef: fileVisualization, clearCanvas: clearFileVisualization } = useAudioFileVisualization(
    hasRecording(name) ? getRecording(name).blob : undefined
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const { canvasRef: oscilloscope } = useOscilloscopeVisualization(oscilloscopeStream);
  const [hasWaveform, setHasWaveform] = useState(false);
  const objectUrlRef = useRef<string>();
  const recordingTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      stopRecording();
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }

      if (objectUrlRef.current) {
        window.URL.revokeObjectURL(objectUrlRef.current);
      }
    };
  }, [stopRecording]);

  useEffect(() => {
    if (!objectUrlRef.current) {
      if (audioBlob) {
        objectUrlRef.current = window.URL.createObjectURL(audioBlob);
      } else if (hasRecording(name)) {
        objectUrlRef.current = window.URL.createObjectURL(getRecording(name).blob);
      }
    }
  }, [audioBlob, getRecording, hasRecording, name]);

  const record = useCallback(() => {
    setHasWaveform(false);
    startVisualization();
    startRecording();

    recordingTimeoutRef.current = setTimeout(() => {
      stopRecording();
      stopVisualization();
      setHasWaveform(true);
    }, timeSlice);
  }, [timeSlice, startRecording, startVisualization, stopRecording, stopVisualization]);

  const remove = useCallback(() => {
    if (window.confirm('Är du säker på att du vill radera inspelningen?')) {
      deleteRecording(name);
      clearMediaBlob();
      clearCanvas();
      clearFileVisualization();
      setHasWaveform(false);
      if (objectUrlRef.current) {
        window.URL.revokeObjectURL(objectUrlRef.current);
        objectUrlRef.current = undefined;
      }
      Mixpanel.track('Action|DeleteRecording');
    }
  }, [clearFileVisualization, clearMediaBlob, deleteRecording, clearCanvas, name]);

  const handleKeyDown = useCallback(
    (e) => {
      if (isRecording) {
        return;
      }

      switch (e.code) {
        case 'Space':
          e.preventDefault();
          if (!hasRecording(name)) {
            record();
          } else {
            setIsPlaying(!isPlaying);
          }
          Mixpanel.track('Action|UseKeyboard', { key: 'Space' });
          break;
        case 'Delete':
          e.preventDefault();
          if (hasRecording(name)) {
            remove();
          }
          Mixpanel.track('Action|UseKeyboard', { key: 'Delete' });
          break;
        case 'ArrowUp':
          e.preventDefault();
          onPrevious && onPrevious(e);
          Mixpanel.track('Action|UseKeyboard', { key: 'ArrowUp' });
          break;
        case 'ArrowDown':
          e.preventDefault();
          onNext && onNext(e);
          Mixpanel.track('Action|UseKeyboard', { key: 'ArrowDown' });
          break;
        default:
          break;
      }
    },
    [record, remove, hasRecording, setIsPlaying, onNext, onPrevious, name, isPlaying, isRecording]
  );

  useEffect(() => {
    if (isActive) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isActive, handleKeyDown]);

  const progressStyle = {
    '--progressBarDuration': `${timeSlice / 1000}s`
  } as React.CSSProperties;

  const stopPlaying = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    if (audioElementRef.current) {
      if (isPlaying) {
        audioElementRef.current.play();
        audioElementRef.current.addEventListener('ended', stopPlaying);
      } else {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.removeEventListener('ended', stopPlaying);
      }
    }

    // Turn off audio loopback while listening to a recording
    if (mediaSource && audioDestination) {
      if (isPlaying) {
        mediaSource.disconnect();
      } else {
        mediaSource.connect(audioDestination);
      }
    }
  }, [isPlaying]); // eslint-disable-line

  useEffect(() => {
    // Turn off audio loopback while recording
    if (mediaSource && audioDestination) {
      if (isRecording) {
        mediaSource.disconnect();
      } else {
        mediaSource.connect(audioDestination);
      }
    }
  }, [isRecording]); // eslint-disable-line

  useEffect(() => {
    if (audioBlob) {
      storeRecording(name, audioBlob, { isDemoRecording: demoMode });
    }
  }, [audioBlob]); // eslint-disable-line

  useEffect(() => {
    if (!isActive) {
      stopPlaying();
    }
  }, [isActive, stopPlaying]);

  const playRecording = () => {
    setIsPlaying(true);
  };

  const variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 }
  };

  const transition = { duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] };

  return (
    <>
      <div
        className={`${styles.wrapper} ${!isActive ? 'pointer' : ''} ${isActive ? styles.active : ''}`}
        onClick={!isRecording ? onClick : undefined}
      >
        <div className={styles.recordingHeader}>
          <strong>
            {order ? <span className={`${styles.orderNumber} ${isActive ? styles.active : ''}`}>{order}</span> : null}
            {t(`page.auscultation.${recordingType}.recording_location.${decamelize(location)}`)}
          </strong>
          {!hasRecording(name) && !isActive ? <span className={styles.noRecording}>Ingen inspelning</span> : null}
        </div>
        <AnimatePresence initial={false}>
          {isActive || hasRecording(name) ? (
            <motion.div
              key="row"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={transition}
            >
              <div className={styles.audioRow}>
                <div className={styles.canvasWrapper}>
                  <div className={`${styles.progress} ${isPlaying ? styles.playing : ''}`} style={progressStyle}></div>
                  <canvas
                    key={name}
                    height={70}
                    ref={
                      hasWaveform || isRecording
                        ? waveform
                        : hasRecording(name) && !audioBlob
                        ? fileVisualization
                        : oscilloscope
                    }
                  />
                </div>
                <Button
                  onClick={!hasRecording(name) ? record : isPlaying ? stopPlaying : playRecording}
                  disabled={status === 'recording'}
                  variant={!hasRecording(name) ? 'primary' : 'secondary'}
                  Icon={!hasRecording(name) ? RecordIcon : undefined}
                  iconPlacement="left"
                >
                  {!hasRecording(name) ? 'Spela in' : isPlaying ? 'Stopp' : 'Spela upp'}
                </Button>
              </div>
              <AnimatePresence initial={false}>
                {isActive && hasRecording(name) ? (
                  <motion.div
                    key="actions"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={variants}
                    transition={transition}
                  >
                    <div className={styles.actions}>
                      <Button
                        variant="destructive"
                        onClick={!isPlaying ? remove : undefined}
                        disabled={!hasRecording(name)}
                        Icon={TrashIcon}
                        iconPlacement="left"
                      >
                        Radera inspelning
                      </Button>
                      {onNext ? (
                        <Button onClick={!isRecording ? onNext : undefined} disabled={!hasRecording(name)}>
                          Nästa
                        </Button>
                      ) : null}
                    </div>
                  </motion.div>
                ) : null}
              </AnimatePresence>
              {objectUrlRef.current ? (
                <audio className={styles.player} ref={audioElementRef} src={objectUrlRef.current} controls={true} />
              ) : null}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </>
  );
}
