import { useEffect, useRef, useState } from 'react';
import { CANVAS_SETTINGS } from '../constants';

export default function useVolumeVisualization(stream?: MediaStream, volumeThreshold?: number) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioCtx = useRef<AudioContext>();
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    let requestId;

    if (requestId && !stream) {
      cancelAnimationFrame(requestId);
    }

    if (canvasRef.current && stream) {
      const canvasCtx = canvasRef.current.getContext('2d');

      if (!canvasCtx) {
        throw new Error('Could not create 2D context');
      }

      if (!audioCtx.current) {
        //@ts-ignore
        audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      const source = audioCtx.current.createMediaStreamSource(stream);

      const analyser = audioCtx.current.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Float32Array(bufferLength);

      source.connect(analyser);

      const cssWidth = parseInt(getComputedStyle(canvasRef.current).getPropertyValue('width').slice(0, -2));

      const scale = window.devicePixelRatio;
      canvasRef.current.width = Math.floor(cssWidth * scale);

      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      let lastVolumeUpdate = 0;
      const draw = (timestamp) => {
        const elapsedSinceLastUpdate = timestamp - lastVolumeUpdate;

        analyser.getFloatTimeDomainData(dataArray);
        canvasCtx.fillStyle = CANVAS_SETTINGS.backgroundColor;
        canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          sum += dataArray[i] * dataArray[i];
        }

        const currentVolume = Math.sqrt(sum / dataArray.length);
        if (volumeThreshold) {
          canvasCtx.fillStyle = `hwb(${Math.min((105 * currentVolume) / volumeThreshold, 105)}deg 25% 13%)`;
        } else {
          canvasCtx.fillStyle = `hsl(333, 82%, 45%)`;
        }
        canvasCtx.fillRect(0, 0, WIDTH * currentVolume, HEIGHT);

        if (volumeThreshold) {
          canvasCtx.fillStyle = `hsl(0, 0%, 49%)`;
          canvasCtx.fillRect(WIDTH * volumeThreshold, 2, 2, HEIGHT - 4);
        }

        if (elapsedSinceLastUpdate > 1000 && currentVolume > volume) {
          setVolume(currentVolume);
          lastVolumeUpdate = timestamp;
        }

        requestId = requestAnimationFrame(draw);
      };

      requestId = requestAnimationFrame(draw);
    }

    return () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    };
  });

  return {
    canvasRef,
    volume
  };
}
