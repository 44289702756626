import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Spinner from 'components/Spinner';
import styles from './Button.module.scss';
import { forwardRef } from 'react';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  children: JSX.Element | string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'destructive';
  elementType?: 'button' | 'link';
  size?: 'standard' | 'large';
  Icon?: any;
  isLoading?: boolean;
  fullWidth?: boolean;
  to?: string;
  disabled?: boolean;
  iconPlacement?: 'left' | 'right';
  onClick?: (e?: any) => void;
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {
  const {
    elementType = 'button',
    variant = 'primary',
    size = 'standard',
    Icon,
    isLoading,
    fullWidth,
    children,
    to,
    disabled,
    iconPlacement,
    ...rest
  } = props;

  const Tag = elementType === 'button' ? 'button' : LinkWrapper;

  return (
    <Tag
      className={`${styles.button} ${styles[variant]} ${styles[size]} ${fullWidth ? styles.fullWidth : styles.inline} ${
        fullWidth && !Icon ? styles.centerText : styles.inline
      } ${isLoading ? styles.disableClick : ''}`}
      disabled={disabled}
      to={to}
      //@ts-ignore
      ref={ref}
      {...rest}
    >
      {isLoading ? (
        <span className={styles.loading}>
          <Spinner variant={variant === 'primary' ? 'secondary' : 'primary'} size="small" />
        </span>
      ) : null}
      {Icon && (!iconPlacement || iconPlacement === 'left') ? (
        <span className={styles.icon}>
          <Icon />
        </span>
      ) : null}
      {children}
      {Icon && iconPlacement === 'right' ? (
        <span className={styles.icon}>
          <Icon />
        </span>
      ) : null}
    </Tag>
  );
});

export default Button;

export const MotionButton = motion(Button);

const LinkWrapper = ({ to, children, ...rest }) => {
  return to ? (
    <Link to={to} {...rest}>
      {children}
    </Link>
  ) : null;
};
