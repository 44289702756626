import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { referPatient } from 'api';
import Button from 'components/Button';
import RadioButton from 'components/RadioButton';
import StatusMessage from 'components/StatusMessage';
import PersonalIdentityNumberVerification from 'components/PersonalIdentityNumberVerification';
import CheckBox from 'components/Checkbox';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { useBackLink } from 'pages/Index';
import { decamelize, isValidPersonalNumber } from 'utils';
import Tooltip from 'components/Tooltip';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { useAuth } from 'hooks/useAuth';

const checkPersonalNumberValidity = (personalNumber: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!isValidPersonalNumber(personalNumber)) {
      reject({ body: { code: 97 } });
    } else {
      resolve(true);
    }
  });
};

export default function ReferPatient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useRef<HTMLFormElement>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [patientError, setPatientError] = useState<any>();
  const [patientErrorHighlight, setPatientErrorHighlight] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const resetRef = useRef<Function>(null);
  const { backLink } = useBackLink();
  const { session } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Code 99 is an error meaning the person is already registered at Blodtrycksdoktorn.
    // In that case we prevent the recommendation from being submitted.
    if (patientError?.body?.code === 99 || patientError?.body?.code === 97) {
      highlightPatientError();
      return;
    }

    const formData = new FormData(e.target);

    setIsSubmitting(true);
    setSubmissionStatus(undefined);

    try {
      await referPatient(formData);
      setSubmissionStatus('success');
      resetForm();
      setSuccessModalVisible(true);
    } catch (err: unknown) {
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    form.current?.reset();
    if (resetRef.current) {
      resetRef.current();
    }
    setPhoneNumber('');
  };

  const highlightPatientError = () => {
    setPatientErrorHighlight(true);
    setTimeout(() => setPatientErrorHighlight(false), 1000);
  };

  const recommendationReasons = session?.user?.serviceOptions?.recommendationReasons || [
    'knownHypertension',
    'suspectedHypertension'
  ];

  return (
    <>
      <div className="narrow-page">
        {backLink}
        <h1>{t('page.recommend.header')}</h1>
        <p>{t('page.recommend.description')}</p>
        <form onSubmit={handleSubmit} ref={form}>
          <PersonalIdentityNumberVerification
            onReset={resetRef}
            onError={(error: any) => setPatientError(error)}
            highlightError={patientErrorHighlight}
            apiMethod={checkPersonalNumberValidity}
            showName={false}
            errorI18nScope="page.recommend.check_if_user"
          />
          <div>
            <label htmlFor="phone-number">{t('global.phone_number')}</label>
            <input
              type="text"
              value={phoneNumber}
              id="phone-number"
              name="phoneNumber"
              minLength={8}
              maxLength={20}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>

          <fieldset>
            <legend>{t('page.recommend.recommendation_reason')}</legend>
            {recommendationReasons.map((reason) => (
              <RadioButton
                key={reason}
                name="recommendationReason"
                id={`reason-${reason}`}
                value={reason}
                data-testid={`radio-${decamelize(reason, '-')}`}
                required
              >
                <div>
                  {t(`page.recommend.reason.${decamelize(reason)}.title`)}

                  {reason === 'certificate' ? (
                    <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                      <Tooltip content={<span>{t('page.recommend.reason.certificate.tooltip')}</span>}>
                        <span style={{ position: 'relative', top: '2px' }}>
                          <QuestionMarkIcon />
                        </span>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                <small>{t(`page.recommend.reason.${decamelize(reason)}.details`)}</small>
                {reason !== 'certificate' ? (
                  <small>
                    <a
                      href={t(`page.recommend.reason.${decamelize(reason)}.url`)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t(`page.recommend.reason.${decamelize(reason)}.link_text`)}
                    </a>
                  </small>
                ) : null}
              </RadioButton>
            ))}
          </fieldset>

          <div>
            <CheckBox id="verify" data-testid="verify-checkbox" required>
              {t('page.recommend.privacy_checkbox')}{' '}
              <small>
                {t('page.recommend.privacy_more_info')}{' '}
                <a href="https://blodtrycksdoktorn.se/integritetspolicy/" target="_blank" rel="noreferrer noopener">
                  {t('global.privacy_policy')}
                </a>
                .
              </small>
            </CheckBox>
          </div>

          <Button type="submit" size="large" isLoading={isSubmitting} fullWidth={true}>
            {t('page.recommend.recommend_patient')}
          </Button>
        </form>
        {submissionStatus === 'error' ? (
          <StatusMessage status={submissionStatus}>{t(`page.recommend.submission_${submissionStatus}`)}</StatusMessage>
        ) : null}

        <div className="mt-2">
          <a
            href="https://blodtrycksdoktorn.se/app/uploads/2023/02/Efter-ha%CC%88nvisning-1.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('page.recommend.pdf_download_link_text')}
          </a>
        </div>
      </div>
      <Modal
        isOpen={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        onAction={() => navigate('/')}
        title={t('page.recommend.dialog_title')}
        actionText={t('page.recommend.dialog_button_end')}
        cancelText={t('page.recommend.dialog_button_continue')}
      >
        <p>{t('page.recommend.dialog_body')}</p>
      </Modal>
    </>
  );
}
