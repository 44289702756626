import { Outlet, useOutletContext } from 'react-router-dom';
import { AuscultationProvider } from 'providers/AuscultationProvider';

export default function Auscultation() {
  return (
    <div>
      <AuscultationProvider>
        <Outlet context={useOutletContext()} />
      </AuscultationProvider>
    </div>
  );
}
