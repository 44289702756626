import styles from './StatusMessage.module.scss';

type StatusMessageProps = {
  children: JSX.Element | string;
  status?: ActionStatus;
};

export default function StatusMessage({ children, status = 'success' }: StatusMessageProps) {
  return <div className={`${styles.statusMessage} ${styles[status]}`}>{children}</div>;
}
