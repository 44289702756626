import { useEffect, useState, useCallback } from 'react';
import { useAuscultation } from './useAuscultation';

interface DeviceInfo extends MediaDeviceInfo {
  isNew: boolean;
}

export default function useDeviceList(kind: MediaDeviceKind, mediaAccessGranted: boolean) {
  const [devices, setDevices] = useState<DeviceInfo[]>([]);
  const [acquiringDevices, setAcquiringDevices] = useState(true);
  const [error, setError] = useState<string>();
  const { setMediaError, setAudioInputDevice } = useAuscultation();

  const updateDeviceList = useCallback(
    (highlightNewlyAddedDevices = false) => {
      const existingDeviceIds = devices.map((device) => device.deviceId).filter((id) => id);

      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            let filteredDevices = devices
              .filter((device) => device.kind === kind)
              .map((device) => {
                const isNew = existingDeviceIds.length > 0 && !existingDeviceIds.includes(device.deviceId);
                return {
                  deviceId: device.deviceId,
                  label: device.label,
                  kind: device.kind,
                  groupId: device.groupId,
                  toJSON: device.toJSON,
                  isNew: highlightNewlyAddedDevices && isNew
                };
              });

            if (filteredDevices.some((d) => d.isNew)) {
              const newDevice = filteredDevices.splice(
                filteredDevices.findIndex((d) => d.isNew),
                1
              )[0];
              filteredDevices = [newDevice, ...filteredDevices];
              setAudioInputDevice(newDevice.deviceId);
            }

            setDevices(filteredDevices);
            setAcquiringDevices(false);
          })
          .catch((error) => {
            setError('enumerationError');
            setMediaError(error);
          });
      } else {
        const errorMessage = 'mediaDevicesUnsupportedError';
        setError(errorMessage);
        setMediaError(errorMessage);
        setAcquiringDevices(false);
      }
    },
    [kind, setMediaError, devices, setAudioInputDevice]
  );

  useEffect(() => {
    const handleDeviceChange = () => updateDeviceList(true);

    if (navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    }

    return () => navigator?.mediaDevices?.removeEventListener('devicechange', handleDeviceChange);
  }, [updateDeviceList]);

  useEffect(() => {
    updateDeviceList();
  }, [kind, mediaAccessGranted]); // eslint-disable-line

  return { devices, acquiringDevices, error };
}
