import { isAfter, addMinutes, differenceInYears } from 'date-fns';
import xRegExp from 'xregexp';
import { LoginResponsePartner } from 'typescript-partner-client';
import { AuscultationSession } from 'providers/AuscultationProvider';

export const isValidSession = (session?: LoginResponsePartner) => {
  if (!session) {
    return false;
  }

  return isAfter(new Date(session.expires), new Date());
};

export const isSessionNearingExpiry = (session?: LoginResponsePartner, threshold = 10) => {
  if (!session) {
    return false;
  }

  const now = new Date();
  const expires = new Date(session.expires);

  return isAfter(now, addMinutes(expires, -1 * threshold));
};

// http://plea.se/me/validatePnum.html
export const isValidPersonalNumber = (personalNumber: string) => {
  let personalNumberToCheck = personalNumber.replace('-', '');

  if (personalNumberToCheck.length < 10) {
    return false;
  } else if (personalNumberToCheck.length === 10) {
    personalNumberToCheck = '19' + personalNumberToCheck;
  } else if (personalNumberToCheck.length === 11) {
    return false;
  }

  var numbers = personalNumberToCheck.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
  var checkSum = 0;

  if (
    !isDate(
      parseInt(personalNumberToCheck.substring(0, 4)),
      parseInt(personalNumberToCheck.substring(4, 6)),
      parseInt(personalNumberToCheck.substring(6, 8))
    )
  ) {
    return false;
  }

  if (numbers === null) {
    return false;
  }

  let n: number;
  for (let i = 3; i <= 12; i++) {
    n = parseInt(numbers[i]);
    if (i % 2 === 0) {
      checkSum += n;
    } else {
      checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
    }
  }

  if (checkSum % 10 === 0) {
    return true;
  }

  return false;
};

function isDate(year: number, month: number, day: number) {
  month = month - 1; // 0-11 in JavaScript
  let date = new Date(year, month, day);

  if (date.getFullYear() === year && month === date.getMonth() && day === date.getDate()) {
    return true;
  }

  return false;
}

export const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const base64string = reader.result;
        if (base64string) {
          resolve(base64string as string);
        } else {
          reject('Could not convert blob to base64');
        }
      },
      false
    );

    reader.readAsDataURL(blob);
  });
};

export const throttle = (fn: Function, timeout: number) => {
  let callable = true;

  return () => {
    if (callable) {
      fn();
      callable = false;
      setTimeout(() => {
        callable = true;
      }, timeout);
    }
  };
};

export const getGenderFromPersonalNumber = (personalNumber: string) => {
  return parseInt(personalNumber.charAt(10), 10) % 2 === 0 ? 'female' : 'male';
};

export const updateObjectInArray = (array: any[], updatedObject: Record<string, any>, index: number) => {
  return array.map((item, i) => {
    if (i !== index) {
      return item;
    }
    return {
      ...item,
      ...updatedObject
    };
  });
};

export function toggleItem<T>(selectedItems: T[], toggledItem: T): T[] {
  let selectedItemsCopy = [...selectedItems];
  const toggledItemIndex = selectedItemsCopy.indexOf(toggledItem);

  if (toggledItemIndex > -1) {
    selectedItemsCopy.splice(toggledItemIndex, 1);
  } else {
    selectedItemsCopy = [...selectedItemsCopy, toggledItem];
  }

  return selectedItemsCopy;
}

export const decamelize = (text: string, separator?: string) => {
  separator = typeof separator === 'undefined' ? '_' : separator;

  const regex1 = xRegExp('([\\p{Ll}\\d])(\\p{Lu})', 'g');
  const regex2 = xRegExp('(\\p{Lu}+)(\\p{Lu}[\\p{Ll}\\d]+)', 'g');

  return text.replace(regex1, `$1${separator}$2`).replace(regex2, `$1${separator}$2`).toLowerCase();
};

export const getAgeFromPersonalNumber = (personalNumber?: string) => {
  if (!personalNumber) return;
  const year = personalNumber.substring(0, 4);
  const month = personalNumber.substring(4, 6);
  const day = personalNumber.substring(6, 8);

  return differenceInYears(new Date(), new Date(`${year}-${month}-${day}`));
};

export const objectFlip = (obj: Record<string | number, string | number>) => {
  return Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {});
};

export const getAuscultationSession = (assessment: Assessment): AuscultationSession => {
  const recordingLocationRequirementIds = getRequirementIds(assessment).auscultationRequirementIds;

  if (Object.values(recordingLocationRequirementIds).some((v) => !v)) {
    throw new Error();
  }

  // @ts-expect-error
  const requirementIds = objectFlip(recordingLocationRequirementIds);
  let completedRecordings: any[] = [];

  completedRecordings = assessment.investigation.observations
    .filter((observation) => Object.keys(requirementIds).includes(observation.requirement.requirementId.toString()))
    .map((observation) => {
      return {
        key: requirementIds[observation.requirement.requirementId] || '',
        value: observation.value
      };
    });

  const uploadedFile = assessment.investigation.observations.find(
    (observation) =>
      observation.requirement.requestedData.codeSystem === 'SNOMED' &&
      observation.requirement.requestedData.code === '468223000'
  );

  const comment = assessment.investigation.observations.find(
    (observation) =>
      observation.requirement.requestedData.codeSystem === 'BTD' &&
      observation.requirement.requestedData.code === 'PARTNER_COMMENT'
  );

  return {
    id: assessment.id.toString(),
    recordings: completedRecordings,
    fileId: uploadedFile?.value || '',
    comment: comment?.value || ''
  };
};

export const getRequirementIds = (assessment: Assessment) => {
  const allRequirements = [
    ...assessment.investigation.observations.map((o) => o.requirement),
    ...assessment.investigation.unmetRequirements
  ];

  const auscultationRequirementIds = {
    heartTopRight: allRequirements.find((r) => r.requestedData.code === 'HEART_TOP_RIGHT')?.requirementId,
    heartTopLeft: allRequirements.find((r) => r.requestedData.code === 'HEART_TOP_LEFT')?.requirementId,
    heartBottomRight: allRequirements.find((r) => r.requestedData.code === 'HEART_BOTTOM_RIGHT')?.requirementId,
    heartBottomLeft: allRequirements.find((r) => r.requestedData.code === 'HEART_BOTTOM_LEFT')?.requirementId,
    lungsTopRight: allRequirements.find((r) => r.requestedData.code === 'LUNGS_TOP_RIGHT')?.requirementId,
    lungsTopLeft: allRequirements.find((r) => r.requestedData.code === 'LUNGS_TOP_LEFT')?.requirementId,
    lungsMidRight: allRequirements.find((r) => r.requestedData.code === 'LUNGS_MID_RIGHT')?.requirementId,
    lungsMidLeft: allRequirements.find((r) => r.requestedData.code === 'LUNGS_MID_LEFT')?.requirementId,
    lungsBottomRight: allRequirements.find((r) => r.requestedData.code === 'LUNGS_BOTTOM_RIGHT')?.requirementId,
    lungsBottomLeft: allRequirements.find((r) => r.requestedData.code === 'LUNGS_BOTTOM_LEFT')?.requirementId
  };

  const commentRequirementId = allRequirements.find((r) => r.requestedData.code === 'PARTNER_COMMENT')?.requirementId;

  return { auscultationRequirementIds, commentRequirementId };
};

type ArrayDeletionAction = {
  index: number;
};

export function removeItemFromArray<T>(array: T[], action: ArrayDeletionAction): T[] {
  return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
}
