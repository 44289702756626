import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import styles from './ErrorFallback.module.scss';

export default function ErrorFallback({ error, resetError }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toStart = () => {
    resetError();
    navigate('/', { replace: true });
  };

  return (
    <div className={styles.wrapper}>
      <section className="stack">
        <h1>{t('page.error_fallback.header')}</h1>
        <div className={styles.errorContent}>{error?.toString()}</div>
        <div>{t('page.error_fallback.body')}</div>
        <div>
          <Button variant="text" onClick={toStart}>
            {t('page.not_found.back_to_start')}
          </Button>
        </div>
      </section>
    </div>
  );
}
