import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { pdfjs } from 'react-pdf';
import App from './App';
import packageJson from '../package.json';
import './styles/global.scss';
import './i18n';
import Mixpanel from 'services/tracking';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const env = process.env.REACT_APP_ENV || 'development';

Sentry.init({
  dsn: 'https://a4bdd6a9bcf146fc95c3ad048aa2e51b@o557843.ingest.sentry.io/6557164',
  release: 'partner-portal@' + packageJson.version,
  environment: env,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  tracesSampleRate: env === 'production' ? 0.01 : 0.1,
  normalizeDepth: 2
});

Mixpanel.init();

// react-i18next requires a Suspense fallback in the component tree since
// translation files are loaded async. In practise they are fetched nearly
// instantly, hence the empty fallback component.

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
