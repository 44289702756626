import { useState } from 'react';
import { useAuscultation } from 'hooks/useAuscultation';
import Button from 'components/Button';
import styles from './EcgUpload.module.scss';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import { useAuth } from 'hooks/useAuth';
import { AUSCULTATION_ONBOARDING_STATUS } from '../../../constants';
import { useTranslation } from 'react-i18next';
import EcgUploadForm from 'components/EcgUploadForm';

export default function EcgUpload() {
  const navigate = useNavigate();
  const { ecgFilename } = useAuscultation();
  const uploadSuccess = !!ecgFilename;
  const [continueWithoutEcgModalActive, setContinueWithoutEcgModalActive] = useState(false);
  const { session } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrapper}>
        <header>
          <h2>EKG-uppladdning</h2>
          <p>
            {t(
              `page.auscultation.ekg.subheader${
                session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                  ? '_assessment'
                  : ''
              }`
            )}
          </p>
        </header>
        {session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED ? (
          <>
            <EcgUploadForm navigateAfterSuccess="/auskultation/flow/summary" />
            <div>
              <Button onClick={() => navigate('/auskultation/flow/summary')} disabled={!uploadSuccess} fullWidth>
                Gå vidare till sammanställning
              </Button>
            </div>
          </>
        ) : null}

        {session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED ? (
          <div className="text-center fs-14 mt-1">
            <Button onClick={() => navigate('/auskultation/flow/summary')}>Gå vidare</Button>
          </div>
        ) : !uploadSuccess ? (
          <div className="text-center fs-14 mt-1">
            <Button variant="text" onClick={() => setContinueWithoutEcgModalActive(true)}>
              Gå vidare utan EKG
            </Button>
          </div>
        ) : null}
      </div>
      <Modal
        isOpen={continueWithoutEcgModalActive}
        onClose={() => setContinueWithoutEcgModalActive(false)}
        onAction={() => navigate('/auskultation/flow/summary')}
        title="Fortsätt utan EKG?"
        actionText="Fortsätt"
        cancelText="Avbryt"
      >
        <p>Om du går vidare utan att ladda upp ett EKG går det att komplettera undersökningen efteråt.</p>
      </Modal>
    </>
  );
}
