import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useAuscultation } from 'hooks/useAuscultation';
import { saveEcgForAuscultationFlow, saveEcgInAssessment } from 'api';
import StatusMessage from 'components/StatusMessage';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import FileUpload from 'components/FileUpload';
import Spinner from 'components/Spinner';

type Props = {
  navigateAfterSuccess?: string;
  onSuccess?(filename: string): void;
};

export default function EcgUploadForm({ navigateAfterSuccess, onSuccess }: Props) {
  const navigate = useNavigate();
  const { setEcgFilename, auscultationId, ecgFilename, demoMode, isNextGen, patient } = useAuscultation();
  const [savingFile, setSavingFile] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(!!ecgFilename);
  const [error, setError] = useState<unknown>();
  const [filename, setFilename] = useState<string | undefined>(ecgFilename);
  const form = useRef<HTMLFormElement>(null);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [numPages, setNumPages] = useState<number>(0);
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleFileChange = async (e) => {
    const file = e.target?.files && e.target.files.length ? e.target.files[0] : undefined;

    if (!file) {
      return;
    }

    setError(undefined);
    const filename = file.name || '';

    if (demoMode) {
      setUploadSuccess(true);
      setEcgFilename(filename);
      return;
    }

    setSelectedFile(file);
    setFilename(filename);
    setPreviewOpen(true);
  };

  const uploadFile = async () => {
    if (!form.current) {
      return;
    }

    setSavingFile(true);
    setError(undefined);

    if (!auscultationId) {
      throw new Error('Auscultation ID missing');
    }

    if (!patient?.id) {
      throw new Error('Patient missing');
    }

    const formData = new FormData(form.current);

    try {
      if (isNextGen) {
        await saveEcgInAssessment(patient.id, auscultationId, formData);
      } else {
        await saveEcgForAuscultationFlow(auscultationId, formData);
      }
      setUploadSuccess(true);
      if (navigateAfterSuccess) {
        setEcgFilename(selectedFile.name);
        navigate(navigateAfterSuccess);
      } else {
        setPreviewOpen(false);
        onSuccess && onSuccess(filename || 'file.pdf');
      }
    } catch (error) {
      setError(error);
    } finally {
      setSavingFile(false);
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <>
      <form onSubmit={handleSubmit} ref={form}>
        <FileUpload
          name="electrocardiogram"
          onChange={handleFileChange}
          label="EKG-fil"
          isUploading={savingFile}
          uploadSuccess={uploadSuccess}
          filename={filename}
        />
      </form>
      {/* Render conditionally to prevent backdrop click handler conflicts when this component is used inside another modal */}
      {previewOpen ? (
        <Modal
          size="xxlarge"
          isOpen={true}
          title="Förhandsgranskning"
          actionText="Ladda upp filen"
          onAction={uploadFile}
          isActionCompleting={savingFile}
          cancelText="Avbryt"
          onClose={() => {
            form.current?.reset();
            setSelectedFile(undefined);
            setFilename('');
            setPreviewOpen(false);
          }}
          hint={
            error ? (
              <StatusMessage status="error">
                Något gick fel när EKG-filen skulle laddas upp. Vänligen försök igen eller kontakta support.
              </StatusMessage>
            ) : null
          }
        >
          <div>
            <Document
              file={selectedFile}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div className="flex align-center justify-center">
                  <Spinner />
                </div>
              }
              noData={''}
              error={'Kunde inte förhandsgranska dokumentet.'}
              onLoadError={() => <span>Något gick fel när dokumentet skulle hämtas.</span>}
              externalLinkTarget="_blank"
            >
              {Array.from(new Array(numPages), (_, index) => (
                <Page width={900} loading={''} key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
