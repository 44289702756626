import styles from './SkeletonLoader.module.scss';

type SkeletonLoaderProps = {
  width?: number | string;
  height?: number | string;
};

export default function SkeletonLoader({ width, height }: SkeletonLoaderProps) {
  return <div className={styles.skeleton} style={{ width, height }}></div>;
}
