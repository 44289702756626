import { Dispatch, SetStateAction } from 'react';
import ReferenceImageMaleHeart from '../../../assets/heart-auscultation-reference-male-blank.png';
import ReferenceImageMaleLungs from '../../../assets/lung-auscultation-reference-male-blank.png';
import ReferenceImageFemaleHeart from '../../../assets/heart-auscultation-reference-female-blank.png';
import ReferenceImageFemaleLungs from '../../../assets/lung-auscultation-reference-female-blank.png';
import styles from './AuscultationReference.module.scss';

type AuscultationReferenceProps = {
  type: 'heart' | 'lungs';
  gender: 'male' | 'female';
  auscultationPoints: string[];
  activePoint: string;
  onPointClick?: Dispatch<SetStateAction<string>>;
  animate: boolean;
  highlightAllPoints: boolean;
};

export default function AuscultationReference({
  type,
  gender,
  auscultationPoints,
  activePoint,
  onPointClick,
  animate,
  highlightAllPoints
}: AuscultationReferenceProps) {
  const getImageSrc = () => {
    if (type === 'heart') {
      return gender === 'male' ? ReferenceImageMaleHeart : ReferenceImageFemaleHeart;
    } else {
      return gender === 'male' ? ReferenceImageMaleLungs : ReferenceImageFemaleLungs;
    }
  };

  return (
    <div className={styles.wrapper}>
      <img src={getImageSrc()} alt="Auskultationsreferens" />
      {auscultationPoints.map((point, i) => (
        <span
          key={point}
          className={`${styles.point} ${styles[`${type}${point}`]} ${
            point === activePoint || highlightAllPoints ? styles.selected : ''
          } ${animate ? styles.animateActive : ''} ${onPointClick ? 'pointer' : ''}`}
          onClick={onPointClick ? () => onPointClick(point) : undefined}
        >
          {i + 1}
        </span>
      ))}
    </div>
  );
}
