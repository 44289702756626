import { useAuscultation } from 'hooks/useAuscultation';
import styles from './DemoBanner.module.scss';

export default function DemoBanner() {
  const { demoMode } = useAuscultation();

  if (!demoMode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <strong>OBS! Demoläge</strong> — Inspelningar kommer inte att sparas
    </div>
  );
}
