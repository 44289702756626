import { Link } from 'react-router-dom';
import styles from './NavBar.module.scss';
import NavBarMenu from './NavBarMenu';

export default function NavBar() {
  return (
    <nav className={styles.navBar} aria-label="top">
      <Link to="/">
        <span className={styles.logo}></span>
      </Link>
      <div className={styles.loggedIn}>
        <NavBarMenu />
      </div>
    </nav>
  );
}
