import useSWR from 'swr';
import { NavLink } from 'react-router-dom';
import Users from '../Users';
import Facilities from '../Facilities';
import { useAuth } from 'hooks/useAuth';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import FacilityInfo from '../FacilityInfo';
import { useTranslation } from 'react-i18next';
import SkeletonLoader from 'components/SkeletonLoader';
import { fetcher } from 'api';

export default function AdministrationIndex() {
  const { session } = useAuth();
  const { t } = useTranslation();
  const { data: partner, isLoading } = useSWR<Partner>('/partner', fetcher);

  return (
    <>
      <div className="stack-3">
        {!session?.user?.facilityGuid ? (
          <div>
            <h2>Tillgängliga partnertjänster</h2>
            <div className="table-header">
              <div>
                {isLoading ? (
                  <SkeletonLoader width="400px" height="1em" />
                ) : (
                  partner?.services
                    ?.filter(
                      (service) => !service.disabledByParent && service.selected && service.service !== 'userAdmin'
                    )
                    .map((service) => t(`global.service.${service.service}`))
                    .join(', ')
                )}
              </div>
              <div>
                <PenIcon />
                <NavLink to="partner/edit">Redigera</NavLink>
              </div>
            </div>
          </div>
        ) : null}

        {!session?.user?.facilityGuid ? (
          <>
            <Facilities />
            <Users />
          </>
        ) : (
          <FacilityInfo facilityId={session?.user?.facilityGuid} />
        )}
      </div>
    </>
  );
}
