import SetupSteps from 'pages/Auscultation/SetupSteps';
import lungAuscultationImage from '../../../../../assets/lung-auscultation-position.jpg';

export default function LungAuscultationSetup({ onContinue }) {
  return (
    <SetupSteps
      onFinish={onContinue}
      steps={[
        <>
          <h2>Inspelning av lungauskultation görs sittandes</h2>
          <img src={lungAuscultationImage} alt="Lungauskultation" width={480} height={320} />
          <ol>
            <li>
              Patienten ska vara <strong>sittandes</strong> vid inspelning, lätt framåtböjd, som en hösäck.
            </li>
            <li>
              För bäst kvalitet rekommenderar vi att inspelning görs <strong>direkt mot huden</strong>.
            </li>
          </ol>
        </>
      ]}
    />
  );
}
