import React from 'react';
import { AuthContext } from 'providers/AuthProvider';

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be within AuthProvider');
  }

  return context;
}
