import environments from '../environments.json';

import {
  OpenAPI as api,
  LoginResponsePartner,
  Patient,
  AuthorizationService,
  PatientService,
  ScreeningResults,
  LoginMethod,
  PatientRecommendationService,
  PatientRecommendations,
  PatientInvite,
  PatientAuscultationService,
  HeartLungAuscultation,
  RecordingKeys
} from 'typescript-partner-client';
import { convertBlobToBase64, isValidPersonalNumber } from 'utils';

api.BASE = environments[process.env.REACT_APP_ENV || 'development']?.apiUrl;

export const fetcher = async (path: string) => {
  const res = await fetch(`${api.BASE}${path}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    }
  });

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // @ts-ignore
    error.body = await res.json();
    // @ts-ignore
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const setAuthToken = (token: string) => {
  api.TOKEN = token;
};

export const loginWithCredentials = async (email?: string, password?: string): Promise<LoginResponsePartner> => {
  const credentials = { identifier: email, password, method: LoginMethod.CREDENTIALS };

  return AuthorizationService.loginUsingPost(navigator.userAgent, api.VERSION, credentials);
};

export const refreshAuthToken = async (jwt: string): Promise<LoginResponsePartner> => {
  return AuthorizationService.loginUsingGet(jwt);
};

export const lookupUser = async (personalNumber: string): Promise<Patient> => {
  // https://github.com/ferdikoomen/openapi-typescript-codegen/issues/889

  return PatientService.getPatientNameUsingPost({ value: personalNumber });
};

export const isNonUser = async (personalNumber: string): Promise<any> => {
  if (!isValidPersonalNumber(personalNumber)) {
    return new Promise((_, reject) => reject({ body: { code: 97 } }));
  }

  return new Promise(async (resolve, reject) => {
    const response = await fetch(`${api.BASE}/partner/patientrecommendation/validate`, {
      headers: {
        authorization: `Bearer ${api.TOKEN}`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ value: personalNumber })
    });

    if (!response.ok) {
      reject({ body: { code: response.status === 403 ? 99 : 98 } });
    } else {
      resolve(response);
    }
  });
};

export const submitScreening = async (formData: FormData) => {
  const personalIdentityNumber = formData.get('personalIdentityNumber')?.toString();
  const normalHeartLungAuscultation = formData.get('normalHeartLungAuscultation')?.toString();
  const heartLungAuscultationFindings = formData.get('heartLungAuscultationFindings')?.toString();
  const otherInformation = formData.get('otherInformation')?.toString();
  const ecgFile = formData.get('electrocardiogram');

  if (!personalIdentityNumber || !normalHeartLungAuscultation || !ecgFile) {
    return new Promise((_, reject) => reject(new Error('Required data missing')));
  }

  const screeningResults: ScreeningResults = {
    personalIdentityNumber: personalIdentityNumber.replace('-', ''),
    normalHeartLungAuscultation: normalHeartLungAuscultation === 'true',
    heartLungAuscultationFindings,
    otherInformation
  };

  const screeningData = {
    screeningResults,
    electrocardiogram: new Blob([ecgFile])
  };

  return PatientService.postScreeningResultsUsingPost(navigator.userAgent, api.VERSION, undefined, screeningData);
};

export const referPatient = async (formData: FormData) => {
  let personalIdentityNumber = formData.get('personalIdentityNumber')?.toString();
  const phoneNumber = formData.get('phoneNumber')?.toString();
  const recommendationReason = formData.get('recommendationReason')?.toString();

  if (!personalIdentityNumber || !recommendationReason) {
    return new Promise((_, reject) => reject(new Error('Required data missing')));
  }

  personalIdentityNumber = personalIdentityNumber.replace('-', '');

  if (personalIdentityNumber.length === 10) {
    personalIdentityNumber = '19' + personalIdentityNumber;
  }

  const invite = {
    personalIdentityNumber,
    recommendationReason: recommendationReason as PatientInvite.recommendationReason,
    phoneNumber
  };

  if (!phoneNumber) {
    delete invite.phoneNumber;
  }

  const patientRecommendations: PatientRecommendations = {
    invites: [invite]
  };

  return PatientRecommendationService.recommendPatientsUsingPost(
    navigator.userAgent,
    api.VERSION,
    patientRecommendations
  );
};

export const beginAuscultation = async (patientGuid: string): Promise<HeartLungAuscultation> => {
  return PatientAuscultationService.beginAuscultationFlowUsingPost(patientGuid);
};

export const storeAuscultationRecording = async (auscultationId: string, name: string, recording: Blob) => {
  const base64 = await convertBlobToBase64(recording);
  return PatientAuscultationService.updateAuscultationFlowUsingPatch(auscultationId, {
    id: auscultationId,
    recordings: [
      {
        key: name as RecordingKeys,
        value: base64
      }
    ]
  });
};

export const storeAuscultationRecordings = async (auscultationId: string, rawRecordings: Record<string, any>) => {
  const recordings = await Promise.all(
    Object.entries(rawRecordings).map(async (entry) => {
      return {
        key: entry[0] as RecordingKeys,
        value: await convertBlobToBase64(entry[1].blob)
      };
    })
  );

  return PatientAuscultationService.updateAuscultationFlowUsingPatch(auscultationId, {
    id: auscultationId,
    recordings
  });
};

export const saveAuscultationComment = async (auscultationId: string, comment = '') => {
  return PatientAuscultationService.updateAuscultationFlowUsingPatch(auscultationId, { id: auscultationId, comment });
};

export const saveEcgForAuscultationFlow = async (auscultationId: string, formData: FormData) => {
  const ecgFile = formData.get('electrocardiogram');

  if (!ecgFile) {
    return new Promise((_, reject) => reject(new Error('ECG file missing')));
  }

  return PatientAuscultationService.addEcgPdfToAuscultationUsingPost(auscultationId, new Blob([ecgFile]));
};

export const getOngoingAuscultation = async (patientGuid: string): Promise<any> => {
  return fetch(`${api.BASE}/partner/auscultation/${patientGuid}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    }
  });
};

export const getUsers = async (): Promise<any> => {
  return fetch(`${api.BASE}/partner/user`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    }
  });
};

export const getUser = async (userId: string): Promise<any> => {
  return fetch(`${api.BASE}/partner/user/${userId}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    }
  });
};

export const updateUser = async (userId: string, updatedUser: any): Promise<Record<string, any>> => {
  return fetch(`${api.BASE}/partner/user/${userId}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(updatedUser)
  });
};

export const addUser = async (user: any) => {
  return fetch(`${api.BASE}/partner/user`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(user)
  });
};

export const removeUser = async (userId: string) => {
  return fetch(`${api.BASE}/partner/user/${userId}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    },
    method: 'DELETE'
  });
};

export const generateSetupCode = async (facilityId: string) => {
  return fetch(`${api.BASE}/partner/facility/${facilityId}/setupcode`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
};

export const updateFacility = async (facilityId: string, updatedFacility: Facility) => {
  return fetch(`${api.BASE}/partner/facility/${facilityId}`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(updatedFacility)
  });
};

export type Auscultation = {
  auscultationId: string;
  patientName: string;
  patientPersonalNumber: string;
  createdAt: string;
  updatedAt: string;
  completedAt?: string;
};

export const getAuscultationHistory = async (userId: string): Promise<any> => {
  return fetch(`${api.BASE}/partner/v2/${userId}/hypertension-physical-examination`, {
    headers: {
      authorization: `Bearer ${api.TOKEN}`
    }
  });
};

export const getAuscultationAssessment = async (patientGuid: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(`${api.BASE}/partner/v2/assessment/${patientGuid}/physical-examination`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${api.TOKEN}`
      }
    }).then((res) => {
      if (!res.ok) {
        reject(res);
      } else {
        resolve(res);
      }
    });
  });
};

export const updateAuscultationObservation = async (
  patientGuid: string,
  assessmentId: string,
  recordingLocation: string,
  recording: Blob,
  auscultationRequirement: Record<string, number | undefined>
): Promise<Response> => {
  const base64 = await convertBlobToBase64(recording);
  const observation = {
    requirementId: auscultationRequirement[recordingLocation],
    value: base64
  };
  return new Promise<Response>((resolve, reject) => {
    if (observation.requirementId === undefined) {
      reject('requirementId missing');
    }

    fetch(`${api.BASE}/partner/v2/assessment/${patientGuid}/${assessmentId}/hypertension-physical-examination`, {
      headers: {
        authorization: `Bearer ${api.TOKEN}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(observation)
    }).then((res) => {
      if (!res.ok) {
        reject(res);
      } else {
        resolve(res);
      }
    });
  });
};

export const saveEcgInAssessment = async (
  patientGuid: string,
  assessmentId: string,
  formData: FormData
): Promise<Response> => {
  const ecgFile = formData.get('electrocardiogram');

  if (!ecgFile) {
    return new Promise((_, reject) => reject(new Error('ECG file missing')));
  }

  return new Promise((resolve, reject) => {
    fetch(`${api.BASE}/partner/v2/assessment/${patientGuid}/${assessmentId}/hypertension-physical-examination/ecg`, {
      headers: {
        authorization: `Bearer ${api.TOKEN}`,
        'Content-Type': 'application/pdf'
      },
      method: 'POST',
      body: new Blob([ecgFile])
    }).then((res) => {
      if (!res.ok) {
        reject(res);
      } else {
        resolve(res);
      }
    });
  });
};

export const savePartnerCommentInAssessment = async (
  patientGuid: string,
  assessmentId: string,
  requirementId: number,
  comment: string
) => {
  const observation = {
    requirementId,
    value: comment
  };
  return new Promise((resolve, reject) => {
    fetch(`${api.BASE}/partner/v2/assessment/${patientGuid}/${assessmentId}/hypertension-physical-examination`, {
      headers: {
        authorization: `Bearer ${api.TOKEN}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify(observation)
    }).then((res) => {
      if (!res.ok) {
        reject(res);
      } else {
        resolve(res);
      }
    });
  });
};

export const beginLoginFlow = async (): Promise<SecureStartResponse> => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${api.BASE}/auth/login/qr`, {
      method: 'POST'
    });

    if (!res.ok) {
      reject(res);
    } else {
      const json = (await res.json()) as SecureStartResponse;
      resolve(json);
    }
  });
};

export const refreshQrCode = async (sessionId: string, signal?: AbortSignal): Promise<BankIdPollingResponse> => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${api.BASE}/auth/partner/login/qr/${sessionId}`, { signal });

    if (!res.ok) {
      reject(res);
    } else {
      const json = (await res.json()) as BankIdPollingResponse;
      resolve(json);
    }
  });
};
