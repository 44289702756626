import { useBackLink } from 'pages/Index';
import RecordingSample from '../AuscultationRecording/RecordingSample';

export default function AuscultationEducation() {
  const { backLink } = useBackLink();

  return (
    <div className="narrow-page education">
      {backLink}
      <h2>Digitalt stetoskop - Riester Ri-Sonic</h2>
      <h3>Fysisk undersökning med digitalt stetoskop</h3>
      <p>
        I detta utbildningsmaterial får du lära dig hur du samlar och skickar in data i partnerportalen när du utför en
        fysisk undersökning med digitalt stetoskop.
      </p>
      <p>
        <a
          href="https://blodtrycksdoktorn.se/fysisk-unders%c3%b6kning-med-digitalt-stetoskop/"
          target="_blank"
          rel="noreferrer"
        >
          Länk till pdf
        </a>
      </p>
      <h3>Hjärt- och lungljud vid auskultation</h3>
      <p>Här kan du lyssna på normala hjärt- och lungljud.</p>
      <RecordingSample type="heart" />
      <RecordingSample type="lungs" />
      <h3>Support</h3>
      <p>
        Hör gärna av dig om du behöver hjälp. Du når oss på{' '}
        <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a> och på telefon 0480-797 960,
        helgfria vardagar mellan kl. 9–11. Vänta kvar efter signalen för att begära uppringning. Vi ringer sedan upp dig
        så snart vi kan.
      </p>
    </div>
  );
}
