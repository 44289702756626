import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuscultation } from 'hooks/useAuscultation';
import { getGenderFromPersonalNumber } from 'utils';
import HeartAuscultationSetup from '../HeartLungAuscultationFlow/HeartAuscultation/HeartAuscultationSetup';
import LungAuscultationSetup from '../HeartLungAuscultationFlow/LungAuscultation/LungAuscultationSetup';
import Button from 'components/Button';
import AuscultationReference from 'pages/Auscultation/AuscultationReference';
import AuscultationList from './AuscultationList';
import {
  HEART_AUSCULTATION_DURATION,
  LUNG_AUSCULTATION_DURATION,
  HEART_RECORDING_LOCATIONS,
  LUNG_RECORDING_LOCATIONS
} from '../../../constants';
import styles from './AuscultationRecording.module.scss';
import RecordingSample from './RecordingSample';
import Mixpanel from 'services/tracking';
import Notice from 'components/Notice';

type AuscultationRecordingProps = {
  type: 'heart' | 'lungs';
};

export default function AuscultationRecording({ type }: AuscultationRecordingProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const recordingLocations = type === 'heart' ? HEART_RECORDING_LOCATIONS : LUNG_RECORDING_LOCATIONS;
  const { hasRecording, hasRecordingOfType, allRecordingsOfTypeComplete, setTimeSlice, patient, isRecording } =
    useAuscultation();
  const [setupActive, setSetupActive] = useState(!allRecordingsOfTypeComplete(type));
  const [activeLocation, setActiveLocation] = useState(
    recordingLocations[searchParams.get('location') !== null ? parseInt(searchParams.get('location')!) - 1 : 0]
  );
  const [recordingBegun, setRecordingBegun] = useState(hasRecordingOfType(type));

  useEffect(() => {
    setTimeSlice(type === 'heart' ? HEART_AUSCULTATION_DURATION : LUNG_AUSCULTATION_DURATION);
  }, [setTimeSlice, type]);

  const markDone = async () => {
    const nextPage = `/auskultation/flow/${type === 'heart' ? 'lungs' : 'ekg'}`;
    navigate(nextPage);
  };

  const allRecordingsComplete = () => {
    return recordingLocations.map((location) => hasRecording(`${type}${location}`)).every((v) => v);
  };

  const goToNext = (e) => {
    e.stopPropagation();
    const activeLocationIndex = recordingLocations.findIndex((location) => location === activeLocation);
    if (activeLocationIndex + 1 <= recordingLocations.length - 1) {
      setActiveLocation(recordingLocations[activeLocationIndex + 1]);
    }
  };

  const goToPrevious = (e) => {
    e.stopPropagation();
    const activeLocationIndex = recordingLocations.findIndex((location) => location === activeLocation);
    if (activeLocationIndex - 1 >= 0) {
      setActiveLocation(recordingLocations[activeLocationIndex - 1]);
    }
  };

  const finishSetup = () => {
    setSetupActive(false);
    Mixpanel.track('Action|SkipSetup', { type });
  };

  const showInstructions = () => {
    setSetupActive(true);
    setRecordingBegun(false);
    Mixpanel.track('Action|ShowInstructionsAgain');
  };

  const Setup = type === 'heart' ? HeartAuscultationSetup : LungAuscultationSetup;

  return (
    <div key={type}>
      {setupActive ? (
        <Setup onContinue={finishSetup} />
      ) : (
        <>
          <div className={styles.recordingWrapper}>
            <div className={`step${recordingLocations.indexOf(activeLocation) + 1}`}>
              <AuscultationReference
                type={type}
                gender={getGenderFromPersonalNumber(patient?.personalNumber || 'male')}
                auscultationPoints={recordingLocations}
                onPointClick={recordingBegun && !isRecording ? setActiveLocation : undefined}
                activePoint={activeLocation}
                animate={recordingBegun}
                highlightAllPoints={!recordingBegun}
              />
              {recordingBegun ? (
                <span className="fs-14">
                  <Button variant="text" onClick={showInstructions}>
                    Visa instruktioner igen
                  </Button>
                </span>
              ) : null}
            </div>
            <AnimatePresence initial={false} exitBeforeEnter>
              {recordingBegun ? (
                <div>
                  <AuscultationList
                    type={type}
                    activeLocation={activeLocation}
                    recordingLocations={recordingLocations}
                    setActiveLocation={setActiveLocation}
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                  />
                  <div className={styles.nextButtonWrapper}>
                    <Button onClick={markDone} disabled={!allRecordingsComplete()} fullWidth>
                      {t(`page.auscultation.${type}.go_to_next`)}
                    </Button>
                  </div>
                </div>
              ) : (
                <motion.div
                  key="instructions"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className={styles.auscultationHeader}>
                    <h2>{t(`page.auscultation.${type}.recording_header`)}</h2>
                  </div>
                  {type === 'heart' ? (
                    <>
                      <ul className={styles.instructionsList}>
                        <li>
                          Inspelning görs på <strong>4 platser</strong> på bröstkorgens framsida enligt den numrerade
                          ordningen som beskrivs av illustrationen.
                        </li>
                        <li>
                          Lyssna efter ett tydligt hjärtslag och låt därefter <strong>3–5 hjärtslag</strong> passera
                          före inspelning för att säkerställa att ljudupptagningen har god kvalitet.
                        </li>
                      </ul>
                      <Notice>
                        <h3>Tips</h3>
                        <ul className={styles.instructionsList}>
                          <li>Se till att det är tyst vid inspelning.</li>
                          <li>Tryck inte stetoskopet för hårt mot huden.</li>
                          <li>Mjukt handlag och mjuka rörelser ger bättre inspelningskvalitet.</li>
                          <li>Be patienten ta ett djupt andetag och hålla andan vid inspelning.</li>
                          <li>
                            Ibland kan det underlätta att använda tangentbordet. Istället för att klicka på{' '}
                            <em>Spela in</em>-knappen kan du starta inspelningen med <kbd>Mellanslag</kbd>.
                          </li>
                          <li>
                            <kbd>Pil upp</kbd> och <kbd>Pil ner</kbd> kan användas för att växla mellan
                            inspelningspunkter, och <kbd>Del</kbd> för att ta bort en inspelning.
                          </li>
                        </ul>
                      </Notice>
                    </>
                  ) : (
                    <>
                      <ul className={styles.instructionsList}>
                        <li>
                          Inspelning görs på <strong>6 punkter</strong> på ryggen enligt den numrerade ordningen som
                          beskrivs av illustrationen till vänster.
                        </li>
                        <li>
                          Lyssna efter <strong>2–3 tydliga andningscykler</strong> före inspelning för att säkerställa
                          att ljudupptagningen har god kvalitet.
                        </li>
                      </ul>
                      <Notice>
                        <h3>Tips</h3>
                        <ul className={styles.instructionsList}>
                          <li>Be patienten att andas djupt in och ut med öppen mun.</li>
                        </ul>
                      </Notice>
                    </>
                  )}
                  <div>
                    <RecordingSample type={type} />
                  </div>
                  <Button onClick={() => setRecordingBegun(true)} fullWidth>
                    {t(`page.auscultation.${type}.start_recording`)}
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
    </div>
  );
}
