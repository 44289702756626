import { useBackLink } from 'pages/Index';

export default function RecommendationEducation() {
  const { backLink } = useBackLink();

  return (
    <div className="narrow-page education">
      {backLink}
      <h2>Om Hänvisning</h2>
      <h3>Vad är blodtryck, högt blodtryck och hypertoni?</h3>
      <p>
        Här har vi samlat grundläggande information om högt blodtryck och hypertoni och hur många som är drabbade och
        vad det innebär.
      </p>
      <p>
        <a
          href="https://blodtrycksdoktorn.se/om-blodtryck-h%c3%b6gt-blodtryck-och-hypertoni/"
          target="_blank"
          rel="noreferrer"
        >
          Ladda ner pdf.
        </a>
      </p>
      <h3>Om Blodtrycksdoktorn</h3>
      <p>
        I detta material kan du lära dig mer om vad Blodtrycksdoktorn gör, vilka som kan bli patienter hos oss och hur
        det går till. Du hittar också information om våra kliniska resultat och studier.
      </p>
      <p>
        <a href="https://blodtrycksdoktorn.se/om-blodtrycksdoktorn-2/" target="_blank" rel="noreferrer">
          Ladda ner pdf.
        </a>
      </p>
      <h3>Om hänvisning</h3>
      <p>Vilka som kan bli hänvisade och hur du gör i Partnerportalen.</p>
      <p>
        <a href="https://blodtrycksdoktorn.se/utbildning-h%c3%a4nvisning/" target="_blank" rel="noreferrer">
          Ladda ner pdf.
        </a>
      </p>
      <h3>Vanliga frågor och svar</h3>
      <p>
        <a href="https://blodtrycksdoktorn.se/vanliga-fragor-och-svar-om-hanvisning/" target="_blank" rel="noreferrer">
          Vanliga frågor och svar om hänvisning.
        </a>
      </p>
      <h3>Lathund om hänvisning för att kunna skriva ut och ha framme</h3>
      <p>
        <a href="https://blodtrycksdoktorn.se/lathund-h%c3%a4nvisning/" target="_blank" rel="noreferrer">
          Ladda ner pdf.
        </a>
      </p>
      <h3>Länk till integritetspolicy</h3>
      <p>
        <a href="https://blodtrycksdoktorn.se/integritetspolicy/" target="_blank" rel="noreferrer">
          Blodtrycksdoktorns integritetspolicy
        </a>
      </p>
      <h3>Support</h3>
      <p>
        Hör gärna av dig om du behöver hjälp. Du når oss på{' '}
        <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a> och på telefon 0480-797 960,
        helgfria vardagar mellan kl. 9–11. Vänta kvar efter signalen för att begära uppringning. Vi ringer sedan upp dig
        så snart vi kan.
      </p>
    </div>
  );
}
