import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import StatusMessage from 'components/StatusMessage';
import CheckBox from 'components/Checkbox';
import Button from 'components/Button';
import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { fetcher } from 'api';
import { toggleItem } from 'utils';
import { useAdministration } from 'hooks/useAdministration';
import { nonFacilityServices } from 'providers/AdministrationProvider';
import styles from '../../Administration/FacilityInfo/FacilityInfo.module.scss';

export default function EditFacility() {
  const params = useParams();
  const { t } = useTranslation();
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [active, setActive] = useState(false);
  const { session } = useAuth();
  const navigate = useNavigate();
  const { updateFacility, updateFacilityError } = useAdministration();
  const { data: facility, error } = useSWR<Facility>(`/partner/facility/${params.facilityId}`, fetcher);

  useEffect(() => {
    if (facility && facility.services) {
      setSelectedServices(facility.services?.filter((service) => service.selected).map((service) => service.service));
      setActive(facility.active || false);
    }
  }, [facility]);

  const update = async (e) => {
    if (!facility || !params.facilityId) {
      return;
    }

    e.preventDefault();
    const formData = new FormData(e.target);

    const services = (facility?.services || []).map(
      (s) => ({ ...s, selected: selectedServices.includes(s.service) } as PartnerServiceState)
    );

    services.push({ service: 'userAdmin', selected: true, disabledByParent: false });

    const updatedFacility: Facility = {
      name: formData.get('name')?.toString(),
      streetaddress: formData.get('streetaddress')?.toString(),
      city: formData.get('city')?.toString(),
      postalCode: formData.get('postalCode')?.toString().replace(' ', ''),
      phoneNumber: formData.get('phoneNumber')?.toString(),
      email: formData.get('email')?.toString(),
      cancellationPolicy: formData.get('cancellationPolicy')?.toString(),
      services,
      active
    };

    updateFacility(params.facilityId, updatedFacility, () => {
      mutate(`/partner/facility/${params.facilityId}`);
      navigate(`/administration/facility/${params.facilityId}`, { state: { flashMessage: 'Ändringarna sparades.' } });
    });
  };

  return error ? (
    <StatusMessage status="error">Det gick inte att hämta mottagningen</StatusMessage>
  ) : (
    <>
      <form onSubmit={update}>
        <h2>Redigera mottagning</h2>
        <div className="pseudo-label">Status</div>
        <div>
          Mottagningen är{' '}
          <strong className={styles[active ? 'active' : 'inactive']}>{active ? 'aktiv' : 'inaktiv'}</strong>.
          <p>
            För att aktivera eller inaktivera en mottagning, kontakta{' '}
            <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a>
          </p>
        </div>
        <div>
          <label>
            Namn
            <input type="text" name="name" defaultValue={facility?.name} required />
          </label>
        </div>
        <div className="flex gap-1">
          <label className="w-30">
            E-postadress
            <input type="text" name="email" defaultValue={facility?.email} required />
          </label>
          <label className="w-30">
            Telefonnummer
            <input type="text" name="phoneNumber" defaultValue={facility?.phoneNumber} required />
          </label>
        </div>
        <div className="flex gap-1">
          <label className="w-30">
            Gatuadress
            <input type="text" name="streetaddress" defaultValue={facility?.streetaddress} required />
          </label>
          <label className="w-30">
            Stad
            <input type="text" name="city" defaultValue={facility?.city} required />
          </label>
          <label className="w-30">
            Postnummer
            <input type="text" name="postalCode" defaultValue={facility?.postalCode} required />
          </label>
        </div>
        {facility?.cancellationPolicy ? (
          <div>
            <label>
              Avbokningspolicy
              <textarea defaultValue={facility?.cancellationPolicy} name="cancellationPolicy" />
            </label>
          </div>
        ) : null}
        <h2>Tjänster</h2>
        <div>
          {facility?.services
            ?.filter((s) => !nonFacilityServices.includes(s.service) && !s.disabledByParent)
            .map((s, i) => (
              <CheckBox
                key={i}
                checked={selectedServices.includes(s.service)}
                id={s.service}
                onChange={() => setSelectedServices(toggleItem(selectedServices, s.service))}
              >
                {t(`global.service.${s.service}`)}
              </CheckBox>
            ))}
        </div>
        <div className="flex space-between align-center">
          <NavLink
            to={session?.user?.facilityGuid ? '/administration' : `/administration/facility/${params.facilityId}`}
          >
            <Chevron /> Gå tillbaka
          </NavLink>
          <Button type="submit">Spara ändringar</Button>
        </div>
        {updateFacilityError ? (
          <StatusMessage status="error">Det gick inte att uppdatera mottagningen</StatusMessage>
        ) : null}
      </form>
    </>
  );
}
