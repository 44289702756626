import useSWR from 'swr';
import SkeletonLoader from 'components/SkeletonLoader';
import { useTranslation } from 'react-i18next';
import { fetcher } from 'api';

export default function EditPartner() {
  const { t } = useTranslation();
  const { data: partner, isLoading } = useSWR<Partner>('/partner', fetcher);

  return (
    <div>
      <h2>{isLoading ? <SkeletonLoader width="300px" height="1em" /> : partner?.name}</h2>
      <div className="pseudo-label">
        Tillgängliga tjänster
        <div>
          <strong>
            {partner?.services
              ?.filter((service) => !service.disabledByParent && service.selected && service.service !== 'userAdmin')
              .map((service) => t(`global.service.${service.service}`))
              .join(', ')}
          </strong>
        </div>
      </div>
      <div className="mt-2">
        För att göra ändringar i vilka tjänster ni har tillgång till, vänligen kontakta{' '}
        <a href="mailto:partner@blodtrycksdoktorn.se">partner@blodtrycksdoktorn.se</a>.
      </div>
    </div>
  );
}
