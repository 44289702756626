import styles from './Tooltip.module.scss';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
};

export default function Tooltip({ children, content }: Props) {
  return (
    <div className={styles.wrapper}>
      <span>{children}</span>
      <div className={styles.content}>
        {content}
        <i></i>
      </div>
    </div>
  );
}
