import SetupSteps from 'pages/Auscultation/SetupSteps';
import heartAuscultationImage from '../../../../../assets/heart-auscultation-position.jpg';

export default function HeartAuscultationSetup({ onContinue }) {
  return (
    <SetupSteps
      onFinish={onContinue}
      steps={[
        <>
          <h2>Förbered patient för hjärtauskultation</h2>
          <img src={heartAuscultationImage} width={480} height={320} alt="Hjärtauskultation" />
          <ol>
            <li>
              Patienten ska vara <strong>sittandes</strong> upprätt vid inspelning.
            </li>
            <li>
              För bästa möjliga inspelning bör ljudupptagning göras <strong>direkt mot hud</strong>.
            </li>
          </ol>
        </>
      ]}
    />
  );
}
