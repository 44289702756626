import { useEffect, useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { useAdministration } from 'hooks/useAdministration';
import Button from 'components/Button';
import StatusMessage from 'components/StatusMessage';
import CheckBox from 'components/Checkbox';
import { useTranslation } from 'react-i18next';
import { removeItemFromArray, updateObjectInArray } from 'utils';
import { useAuth } from 'hooks/useAuth';
import Listbox from 'components/Listbox';
import { fetcher } from 'api';
import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg';
import { nonUserServices } from 'providers/AdministrationProvider';

type Props = {
  editMode?: boolean;
};

const TITLES = [
  'NURSE',
  'DOCTOR',
  'ADMINISTRATOR',
  'PHYSIOTHERAPIST',
  'NAPRAPATH',
  'CHIROPRACTOR',
  'ASSISTANTNURSE',
  'OTHER'
];

export default function CreateUser({ editMode }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    addUser,
    updateUser,
    removeUser,
    removingUser,
    updatingUser,
    removeUserError,
    addUserError,
    updateUserError,
    addingUser
  } = useAdministration();
  const { session } = useAuth();
  const [services, setServices] = useState<PartnerServiceState[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>();
  const [availableServices, setAvailableServices] = useState<PartnerServiceState[]>([]);
  const [handlesCertificatePatients, setHandlesCertificatePatients] = useState(false);
  const { data: user, error: getUserError } = useSWR<PartnerUser>(
    editMode && params.userId ? `/partner/user/${params.userId}` : null,
    fetcher
  );
  const { data: facility } = useSWR<Facility>(
    params.facilityId ? `/partner/facility/${params.facilityId}` : null,
    fetcher
  );
  const { data: partner } = useSWR<Partner>('/partner', fetcher);

  const returnPath = params.facilityId ? `/administration/facility/${params.facilityId}` : '/administration';

  useEffect(() => {
    if (user) {
      if (user.title) {
        setSelectedTitle(user.title);
      }
      if (user.services) {
        setServices(user.services);
      }
      if (user.serviceOptions?.recommendationReasons?.includes('certificate')) {
        setHandlesCertificatePatients(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!availableServices.length) {
      if (facility?.services) {
        setAvailableServices(facility.services.filter((s) => s.selected && !s.disabledByParent));
      } else if (partner?.services) {
        setAvailableServices(partner.services.filter((s) => s.selected));
      }
    }
  }, [partner, facility, availableServices.length]);

  const getUserFromFormData = (e): PartnerUser => {
    const formData = new FormData(e.target);
    let recommendationReasons = partner?.recommendationInfo?.recommendationReasons || [];
    if (!handlesCertificatePatients && recommendationReasons.includes('certificate')) {
      recommendationReasons = removeItemFromArray(recommendationReasons, {
        index: recommendationReasons.findIndex((r) => r === 'certificate')
      });
    }

    const user: PartnerUser = {
      personalNumber: formData.get('personalNumber')?.toString().replace('-', ''),
      givenName: formData.get('givenName')?.toString(),
      familyName: formData.get('familyName')?.toString(),
      email: formData.get('email')?.toString(),
      title: selectedTitle,
      services: availableServices.map((service) => ({
        ...service,
        selected: services
          .filter((s) => s.selected)
          .map((s) => s.service)
          .includes(service.service)
      }))
    };

    if (
      services.find(
        (service) => service.service === 'recommendPatients' && service.selected && !service.disabledByParent
      )
    ) {
      user.serviceOptions = { recommendationReasons };
    }

    return user;
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = getUserFromFormData(e);

    updateUser({ guid: params.userId, ...updatedUser }, () => {
      mutate(`/partner/user/${params.userId}`);
      navigate(returnPath, { state: { flashMessage: 'Ändringarna sparades.' } });
    });
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const newUser = getUserFromFormData(e);

    newUser.partnerGuid = session?.user?.partnerGuid;
    newUser.facilityGuid = params.facilityId || session?.user?.facilityGuid;

    addUser(newUser, () =>
      navigate(returnPath, {
        state: { flashMessage: `Användaren ${newUser.givenName} ${newUser.familyName} skapades.` },
        replace: true
      })
    );
  };

  const remove = (e) => {
    e.preventDefault();
    if (params.userId && window.confirm('Är du säker på att du vill ta bort användaren?')) {
      removeUser(params.userId, () => {
        mutate(`/partner/user/${params.userId}`);
        navigate(returnPath, { replace: true, state: { flashMessage: 'Användaren togs bort.' } });
      });
    }
  };

  const handlePermissionChange = (service) => {
    const updatedService = services.find((s) => s.service === service);

    if (updatedService) {
      updatedService.selected = !updatedService?.selected;
      const updatedServiceIndex = services.findIndex((s) => s.service === service);
      setServices(updateObjectInArray(services, updatedService, updatedServiceIndex));
    } else {
      setServices([...services, { service, selected: true, disabledByParent: false }]);
    }
  };

  const titleOptions = [
    {
      id: 0,
      value: null,
      label: t('page.user_administration.no_selected_title')
    },
    ...TITLES.map((title, i) => ({
      id: i + 1,
      value: title,
      label: t(`page.user_administration.titles.${title.toLowerCase()}`)
    }))
  ];

  return getUserError ? (
    <StatusMessage status="error">Det gick inte att hitta användaren.</StatusMessage>
  ) : (
    <>
      <form onSubmit={editMode ? handleUpdateSubmit : handleAddSubmit}>
        <h2>
          {editMode
            ? 'Redigera användare'
            : params.facilityId || session?.user?.facilityGuid
            ? 'Lägg till användare'
            : 'Lägg till global användare'}
          {facility || session?.user?.facilityName ? ` hos ${facility?.name || session?.user?.facilityName}` : ''}
        </h2>
        <div className="flex gap-1">
          <label className="w-30">
            {t('page.user_administration.given_name')}
            <input type="text" name="givenName" defaultValue={user?.givenName} required />
          </label>
          <label className="w-30">
            {t('page.user_administration.family_name')}
            <input type="text" name="familyName" defaultValue={user?.familyName} required />
          </label>
        </div>
        <div>
          <label className="w-30">
            {t('page.user_administration.personal_identity_number')}
            <input
              type="text"
              name="personalNumber"
              placeholder={t('global.personal_identity_number_format')}
              defaultValue={user?.personalNumber}
              required
            />
          </label>
        </div>
        <div>
          <label className="w-30">
            {t('page.user_administration.email')}
            <input type="text" name="email" defaultValue={user?.email} required />
          </label>
        </div>
        <div className="w-30">
          <label>{t('page.user_administration.title')}</label>
          <Listbox
            items={titleOptions}
            onSelectItem={(item) => setSelectedTitle(item.value)}
            defaultSelected={titleOptions.find((x) => x.value === selectedTitle) || titleOptions[0]}
          />
        </div>
        <h2>Rättigheter</h2>
        <div>
          {availableServices
            .filter((service) => !nonUserServices.includes(service.service) && !service.disabledByParent)
            .map((service, i) => (
              <CheckBox
                key={i}
                checked={services
                  .filter((s) => s.selected)
                  .map((s) => s.service)
                  .includes(service.service)}
                id={service.service}
                onChange={() => handlePermissionChange(service.service)}
              >
                {t(`global.permission.${service.service}`)}
              </CheckBox>
            ))}
        </div>
        {services
          .filter((service) => service.selected && !service.disabledByParent)
          .map((service) => service.service)
          .includes('recommendPatients') &&
        partner?.recommendationInfo?.recommendationReasons?.includes('certificate') ? (
          <>
            <h2>Övrigt</h2>
            <div>
              <CheckBox
                checked={handlesCertificatePatients}
                id="certificate-checkbox"
                onChange={() => setHandlesCertificatePatients(!handlesCertificatePatients)}
              >
                Hanterar intygspatienter
              </CheckBox>
            </div>
          </>
        ) : null}
        <div className="flex space-between align-center">
          <NavLink to={returnPath}>
            <Chevron /> Gå tillbaka
          </NavLink>
          <div className="flex gap-1">
            {editMode && session?.user?.guid !== params.userId ? (
              <Button variant="destructive" isLoading={removingUser} onClick={remove}>
                {t(`page.user_administration.remove`)}
              </Button>
            ) : null}
            <Button type="submit" isLoading={editMode ? updatingUser : addingUser}>
              {editMode ? 'Spara ändringar' : 'Skapa användaren'}
            </Button>
          </div>
        </div>
        {updateUserError ? (
          <StatusMessage status="error">{t(`page.user_administration.errors.update_failed`)}</StatusMessage>
        ) : null}
        {addUserError ? (
          <StatusMessage status="error">{t(`page.user_administration.errors.creation_failed`)}</StatusMessage>
        ) : null}
      </form>
      {removeUserError ? (
        <StatusMessage status="error">{t(`page.user_administration.errors.removal_failed`)}</StatusMessage>
      ) : null}
    </>
  );
}
