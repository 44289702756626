import { saveAuscultationComment, storeAuscultationRecordings, savePartnerCommentInAssessment } from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import StatusMessage from 'components/StatusMessage';
import { useAuscultation } from 'hooks/useAuscultation';
import { useState } from 'react';
import {
  AUSCULTATION_ONBOARDING_STATUS,
  HEART_RECORDING_LOCATIONS,
  LUNG_RECORDING_LOCATIONS
} from '../../../constants';
import styles from './AuscultationSummary.module.scss';
import RecordingRow from './RecordingRow';
import { useNavigate } from 'react-router-dom';
import ThumbsUp from 'assets/thumbs-up.png';
import Mixpanel from 'services/tracking';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

export default function AuscultationSummary() {
  const [savingComment, setSavingComment] = useState(false);
  const [error, setError] = useState<unknown>();
  const {
    ecgFilename,
    auscultationId,
    getUnsavedRecordings,
    markAllRecordingsSaved,
    allRecordingsOfTypeComplete,
    clearLocalData,
    demoMode,
    setDemoMode,
    storedComment,
    patient,
    isNextGen,
    requirementIds,
    saveUnsavedNextGenRecordings
  } = useAuscultation();
  const [auscultationFinishedModalVisible, setAuscultationFinishedModalVisible] = useState(false);
  const navigate = useNavigate();
  const { session, updateUserProperty } = useAuth();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (demoMode) {
      setAuscultationFinishedModalVisible(true);
      return;
    }

    setSavingComment(true);
    setError(undefined);

    const formData = new FormData(e.target);
    const comment = formData.get('comment')?.toString();

    if (!auscultationId) {
      throw new Error('Auscultation ID missing');
    }

    try {
      if (isNextGen) {
        if (!requirementIds.commentRequirementId) {
          throw new Error('comment requirementId missing');
        }

        if (!patient?.id) {
          throw new Error('Patient missing');
        }

        await saveUnsavedNextGenRecordings();

        await savePartnerCommentInAssessment(
          patient.id,
          auscultationId,
          requirementIds.commentRequirementId,
          comment || ''
        );
      } else {
        const unsavedRecordings = getUnsavedRecordings();
        if (Object.keys(unsavedRecordings).length) {
          await storeAuscultationRecordings(auscultationId, unsavedRecordings);
          markAllRecordingsSaved();
          Mixpanel.track('Action|SaveUnsavedRecordings');
        }
        await saveAuscultationComment(auscultationId, comment);
      }
      setAuscultationFinishedModalVisible(true);
    } catch (error) {
      setError(error);
    } finally {
      setSavingComment(false);
    }
  };

  const completeAuscultation = async () => {
    await clearLocalData();
    if (session?.user?.properties?.auscultationOnboarding !== AUSCULTATION_ONBOARDING_STATUS.ACCEPTED) {
      Mixpanel.track('Flow|Finish|TestAuscultation');
      updateUserProperty('auscultationOnboarding', AUSCULTATION_ONBOARDING_STATUS.PENDING);
      navigate('/');
    } else {
      Mixpanel.track('Flow|Finish|Auscultation', { isNextGen: !!patient?.nextGeneration });
      navigate('/auskultation');
    }
    setDemoMode(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <header>
          <h2>Sammanställning</h2>
        </header>
        <div className={styles.columns}>
          <div className={styles.column}>
            <h3>Hjärtauskultation</h3>
            {HEART_RECORDING_LOCATIONS.map((location, index) => (
              <RecordingRow key={index} recordingType="heart" location={location} index={index + 1} />
            ))}
          </div>
          <div className={styles.column}>
            <h3>Lungauskultation</h3>
            {LUNG_RECORDING_LOCATIONS.map((location, index) => (
              <RecordingRow key={index} recordingType="lungs" location={location} index={index + 1} />
            ))}
            <form onSubmit={handleSubmit}>
              <label htmlFor="comment">Kommentar</label>
              <textarea
                id="comment"
                name="comment"
                defaultValue={storedComment ?? ''}
                placeholder="Skriv din kommentar här"
              ></textarea>
              <Button
                isLoading={savingComment}
                disabled={!allRecordingsOfTypeComplete('heart') || !allRecordingsOfTypeComplete('lungs')}
                fullWidth
              >
                {t(
                  `page.auscultation.summary.submit.${
                    session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                      ? 'standard'
                      : 'assessment'
                  }`
                )}
              </Button>
            </form>
          </div>
          <div className={styles.column}>
            <h3>EKG</h3>
            <div className={`${styles.uploadedFile} ${ecgFilename ? styles.successIcon : ''}`}>
              {ecgFilename || 'Ingen fil uppladdad'}
            </div>
          </div>
        </div>
        {error ? (
          <StatusMessage status="error">
            Något gick fel när undersökningen skulle skickas. Vänligen försök igen eller kontakta support.
          </StatusMessage>
        ) : null}
      </div>
      <Modal
        actionText="Klar"
        isOpen={auscultationFinishedModalVisible}
        onClose={() => setAuscultationFinishedModalVisible(false)}
        onAction={completeAuscultation}
        size="small"
      >
        <div className={styles.modalContent}>
          <img src={ThumbsUp} alt="Tumme upp" />
          <p>
            {t(
              `page.auscultation.summary.success_modal.${
                session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                  ? 'standard'
                  : 'assessment'
              }`
            )}
          </p>
        </div>
        {!ecgFilename && !demoMode ? (
          <p className="mt-1">{t('page.auscultation.summary.success_modal.ecg_missing')}</p>
        ) : null}
      </Modal>
    </>
  );
}
