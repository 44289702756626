import { useEffect, useState, useRef } from 'react';
import Mixpanel from 'services/tracking';
import { useAuscultation } from './useAuscultation';

export default function useAudioStream() {
  const audioStream = useRef<MediaStream | undefined>();
  const [audioStreamId, setAudioStreamId] = useState('unset');
  const [audioStreamError, setAudioStreamError] = useState<unknown>();
  const { audioInputDevice, setMediaError, setAudioInputDevice } = useAuscultation();
  const [mediaAccessGranted, setMediaAccessGranted] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!window.navigator.mediaDevices) {
      setAudioStreamError(new Error('Media Devices missing'));
    } else {
      startAudioStream(audioInputDevice);
    }
  }, []); // eslint-disable-line

  const startAudioStream = async (deviceId?: string) => {
    if (!window.navigator.mediaDevices) {
      setAudioStreamError(new Error('Media Devices missing'));
      return;
    }

    // Stop any existing stream first
    endAudioStream();

    try {
      let stream = await window.navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: deviceId ?? 'default',
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false
        }
      });

      audioStream.current = stream;
      setAudioStreamId(stream.id);
      setMediaAccessGranted(true);
      if (!audioInputDevice) {
        setAudioInputDevice('default');
      }
    } catch (error) {
      setAudioStreamError(error);
      setMediaError(error);
      setMediaAccessGranted(false);
      Mixpanel.track('Error|MediaAccessDenied');
    }
  };

  const endAudioStream = () => {
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => track.stop());
      audioStream.current = undefined;
    }
  };

  return {
    startAudioStream,
    endAudioStream,
    audioStreamId,
    audioStreamError,
    audioStream: audioStream.current,
    mediaAccessGranted
  };
}
