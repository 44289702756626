import { Popover as HeadlessPopover } from '@headlessui/react';
import { motion } from 'framer-motion';
import styles from './Popover.module.scss';

type Props = {
  trigger: Function | React.ReactNode;
  children: React.ReactNode;
};

export default function Popover({ trigger, children }: Props) {
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -10 }
  };

  return (
    <HeadlessPopover className={styles.wrapper}>
      {({ open }) => (
        <>
          <HeadlessPopover.Button className={styles.trigger}>
            {typeof trigger === 'function' ? trigger(open) : trigger}
          </HeadlessPopover.Button>

          <HeadlessPopover.Panel>
            <motion.div
              className={styles.panel}
              initial="closed"
              variants={variants}
              transition={{ duration: 0.1 }}
              animate={open ? 'open' : 'closed'}
            >
              {children}
            </motion.div>
          </HeadlessPopover.Panel>
        </>
      )}
    </HeadlessPopover>
  );
}
