import { useEffect, useState } from 'react';
import { HEART_AUSCULTATION_DURATION } from '../../../../constants';
import { HEART_AUSCULTATION_SAMPLE, LUNG_AUSCULTATION_SAMPLE } from '../../../../constants/sampleRecordings';
import RecordingRow from 'pages/Auscultation/AuscultationSummary/RecordingRow';
import { useTranslation } from 'react-i18next';

export default function RecordingSample({ type }) {
  const { t } = useTranslation();
  const [blob, setBlob] = useState<Blob>();
  const base64Data = type === 'heart' ? HEART_AUSCULTATION_SAMPLE : LUNG_AUSCULTATION_SAMPLE;

  useEffect(() => {
    fetch(base64Data)
      .then((response) => response.blob())
      .then((blob) => {
        setBlob(blob);
      });
  }, [base64Data]);

  return blob ? (
    <RecordingRow
      recordingType={type}
      header={t(`page.auscultation.${type}.sample`)}
      audioBlob={blob}
      hasAudioMetadata={false}
      duration={type === 'heart' ? HEART_AUSCULTATION_DURATION : 15_000}
    />
  ) : null;
}
