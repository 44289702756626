import { Link, Outlet, useOutletContext } from 'react-router-dom';
import NavBar from './NavBar';
import styles from './Index.module.scss';
import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg';

export default function Index() {
  return (
    <main className={styles.mainContainer}>
      <NavBar />
      <div className={styles.pageContent}>
        <main>
          <Outlet
            context={{
              backLink: (
                <div className={`${styles.backLink}`}>
                  <Link to="/">
                    <Chevron /> Tillbaka till startsidan
                  </Link>
                </div>
              )
            }}
          />
        </main>
      </div>
    </main>
  );
}

type ContextType = {
  backLink: React.ReactNode;
};

export function useBackLink() {
  return useOutletContext<ContextType>() ?? {};
}
