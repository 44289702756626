export default function CheckBox({ children, ...rest }) {
  return (
    <>
      <input type="checkbox" name={rest.name} id={rest.id} {...rest} />
      <label className="flex-label" htmlFor={rest.id}>
        <div>{children}</div>
      </label>
    </>
  );
}
